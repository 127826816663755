import { useCallback, useContext, useEffect } from "react";

import { FormCategory, FormSubmissionStatus } from "@simplyk/common";

import { useDebounce } from "../components/TextField/useDebounce";
import { isTest } from "../constants/env";
import { FrontendFormContext } from "../contexts/FrontendFormContext";
import { SessionContext } from "../contexts/SessionContext";
import { trpc } from "../helpers/trpc";

type UpsertVisitorTrackingParams = {
  formId: string;
  organizationId: string;
  formSubmissionStatus: FormSubmissionStatus;
  firstName?: string | undefined;
  lastName?: string | undefined;
  email?: string | undefined;
  formCategory: FormCategory | undefined;
};

export const useUpsertVisitorTracking = ({
  props,
  isDirty,
  shouldCancel,
}: {
  props: UpsertVisitorTrackingParams;
  isDirty: boolean;
  shouldCancel: boolean;
}) => {
  const { formType, formData, organization, isSubmitting } = useContext(FrontendFormContext);
  const { sessionId } = useContext(SessionContext);

  const { mutateAsync: upsertVisitorTracking } = trpc.upsertVisitorTracking.useMutation();

  const onUpdateCallback = useCallback(
    async (params: UpsertVisitorTrackingParams) => {
      if (isSubmitting || !isDirty || !formType || !formData) {
        return;
      }

      await upsertVisitorTracking({
        firstName: params.firstName ?? undefined,
        lastName: params.lastName ?? undefined,
        email: params.email ?? undefined,
        sessionId,
        organizationId: organization.id,
        formCategory: params.formCategory ?? undefined,
        formId: formData.id,
        formSubmissionStatus: FormSubmissionStatus.Incomplete,
      });
    },
    [formData, formType, isDirty, isSubmitting, organization.id, sessionId, upsertVisitorTracking]
  );

  useDebouncedCallbackOnUpdate(props, onUpdateCallback, shouldCancel);
};

// Formats data and executes callback with a 2 seconds debounce. This is typically suited for frequent graphQL mutations.
const useDebouncedCallbackOnUpdate = <T extends object>(
  props: T,
  callback: (params: T) => void,
  shouldCancel: boolean
): void => {
  const onChange = useCallback(
    async (cbProps: T) => {
      const params: Record<string, unknown> = {};
      Object.entries(cbProps).map(([key, value]: [string, unknown]) => {
        if (typeof value !== "undefined") {
          // Convert empty string to null values.
          params[key] = value === "" ? null : value;
        }
      });

      if (!isTest) {
        callback(params as T);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(props)]
  );

  const { setDebouncedValue, cancel } = useDebounce<T>({
    onChange,
    // we want to debounce less in test mode because cypress can fill a form in less than 2 seconds
    debounced: 5_000,
  });

  useEffect(() => {
    setDebouncedValue(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDebouncedValue, JSON.stringify(props)]);

  useEffect(() => {
    if (shouldCancel) {
      cancel();
    }
  }, [cancel, shouldCancel]);
};

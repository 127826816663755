import { FieldValues, Path, UseFormReturn, useWatch } from "react-hook-form";

import { ParticipantAnswers } from "@/types/ticketing";

export const useAnswersOfParticipantsSelectedChoicesCount = <T extends FieldValues>(
  form: UseFormReturn<T>
): Record<string, number> | undefined => {
  const { control } = form;

  const customAnswersOfParticipants = useWatch({ control, name: "customAnswersOfParticipants" as Path<T> }) as
    | ParticipantAnswers[]
    | undefined;

  return customAnswersOfParticipants
    ?.filter((answer) => Boolean(answer))
    .reduce(
      (acc, current) => {
        const choiceIds = current.answers?.flatMap((answer) => answer.choiceIds) || [];
        choiceIds.forEach((choiceId) => {
          acc[choiceId] = (acc[choiceId] || 0) + 1;
        });
        return acc;
      },
      {} as Record<string, number>
    );
};

import { memo } from "react";

import { styled, Theme } from "@mui/material/styles";
import PhoneInput from "react-phone-input-2";

import { InternalClasses } from "../helpers";

import {
  getBaseInputInputStyles,
  getBaseInputContainerStyles,
  getBaseInputFocusedStyles,
  getSizeStyles,
} from "@/design-system/BaseInput/styles";

export type BasePhoneNumberInputBaseClasses = "default" | "containerForm" | "input" | "button" | "focus" | "error";

const PREFIX = "BasePhoneNumberInput";

export const zeffyPhoneNumberClasses: InternalClasses<typeof PREFIX, BasePhoneNumberInputBaseClasses> = {
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  default: `${PREFIX}-default`,
  containerForm: `${PREFIX}-containerForm`,
  focus: `${PREFIX}-focus`,
  error: `${PREFIX}-error`,
};

export const StyledPhoneInput = memo(
  styled(PhoneInput)(({ theme }: { theme: Theme }) => ({
    [`& .${zeffyPhoneNumberClasses.input}`]: {},
    [`& .${zeffyPhoneNumberClasses.button}`]: {},

    [`&.${zeffyPhoneNumberClasses.default}`]: {
      ...getBaseInputContainerStyles("neutral", theme),
      "&:hover": {
        borderColor: theme.palette.primary.main,
      },
      "& .flag-dropdown .country-list .flag": {
        position: "static",
      },
      [`& .${zeffyPhoneNumberClasses.input}.form-control`]: {
        ...getBaseInputInputStyles(theme),
        ...getSizeStyles("medium", theme),
        width: "100%",
        border: "none",
        paddingLeft: 48,
        "&.invalid-number": {
          backgroundColor: "inherit",
          border: "none",
          "&:focus": {
            backgroundColor: "inherit",
            border: "none",
          },
        },
      },
      [`& .${zeffyPhoneNumberClasses.button}.flag-dropdown, .${zeffyPhoneNumberClasses.button}.flag-dropdown.open`]: {
        border: "none",
        borderRight: `1px solid ${theme.palette.neutralVariant[90]}`,
        borderRadius: `${theme.radius(1)} 0 0 ${theme.radius(1)}`,
        "& .selected-flag, .open.selected-flag": {
          border: "none",
          borderRadius: `${theme.radius(1)} 0 0 ${theme.radius(1)}`,
        },
        "&.invalid-number": {
          borderColor: theme.palette.error.main,
        },
      },
    },

    [`&.${zeffyPhoneNumberClasses.containerForm}`]: {
      ...getBaseInputContainerStyles("form", theme),
      [`& .${zeffyPhoneNumberClasses.button}.flag-dropdown`]: {
        borderRight: `1px solid ${theme.palette.border.form.quiet}`,
      },
    },

    [`&.${zeffyPhoneNumberClasses.focus}`]: {
      marginTop: 0,
      ...getBaseInputFocusedStyles(theme),
    },

    [`&.${zeffyPhoneNumberClasses.error}`]: {
      borderColor: theme.palette.error.main,
      "&:hover": {
        borderColor: theme.palette.error.main,
      },
    },
  }))
);

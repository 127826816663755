import { useEffect, useState } from "react";

import { useRouter } from "next/router";

import { LiveEditorStep, LiveEditorStepKey, isValidStepKey } from "@/features/LiveFormEditor/Steps/LiveEditorStep.enum";
import { TicketingOutput, DonationFormOutput } from "@/types/trpc";

export const useStepNavigation = (
  steps: { key: LiveEditorStepKey; shouldBeSkip?: boolean }[],
  apiFormObject: TicketingOutput | DonationFormOutput
) => {
  const router = useRouter();
  const initialStepKey = getInitialStepKey(apiFormObject);
  const [currentStepKey, setCurrentStepKey] = useState<LiveEditorStepKey>(initialStepKey);
  const [isStepChanging, setIsStepChanging] = useState(false);

  const liveEditorStepsKeys = steps.filter((step) => !step.shouldBeSkip).map((step) => step.key);
  const liveEditorStepsIndexes = liveEditorStepsKeys.map((key, index) => index);

  const getIndexStepFromKey = (key: string): LiveEditorStep =>
    liveEditorStepsKeys.findIndex((step) => step === key) as LiveEditorStep;

  const getKeyStepFromIndex = (index: LiveEditorStep): LiveEditorStepKey =>
    liveEditorStepsKeys[index] as LiveEditorStepKey;

  const currentStepIndex = getIndexStepFromKey(currentStepKey);

  useEffect(() => {
    // Handle initial hash and browser back/forward
    const handleHashChange = () => {
      // Use window.location.hash to ensure we get the latest hash
      const hash = window.location.hash.slice(1);
      if (hash && isValidStepKey(hash) && hash !== currentStepKey) {
        setCurrentStepKey(hash);
      }
    };

    // Set initial hash if none exists
    if (!window.location.hash) {
      window.location.hash = initialStepKey;
    } else {
      handleHashChange();
    }

    router.events.on("hashChangeComplete", handleHashChange);
    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
      router.events.off("hashChangeComplete", handleHashChange);
    };
  }, [currentStepKey, initialStepKey, router]);

  const updateCurrentStep = (stepKey: LiveEditorStepKey) => {
    setIsStepChanging(true);
    setCurrentStepKey(stepKey);
    window.location.hash = stepKey;
    setIsStepChanging(false);
  };

  const goToNextStep = () => {
    const nextStepKey = getKeyStepFromIndex(currentStepIndex + 1);
    updateCurrentStep(nextStepKey);
  };

  const goToPreviousStep = () => {
    const previousStepKey = getKeyStepFromIndex(currentStepIndex - 1);
    updateCurrentStep(previousStepKey);
  };

  return {
    currentStepIndex,
    currentStepKey,
    updateCurrentStep,
    goToNextStep,
    goToPreviousStep,
    isStepChanging,
    setIsStepChanging,
    getIndexStepFromKey,
    getKeyStepFromIndex,
    liveEditorStepsKeys,
    liveEditorStepsIndexes,
  };
};

const getInitialStepKey = (apiFormObject: TicketingOutput | DonationFormOutput): LiveEditorStepKey => {
  const hash = typeof window !== "undefined" ? window.location.hash.slice(1) : "";
  if (hash && isValidStepKey(hash)) {
    return hash;
  }

  const lastSavedDraftStep = apiFormObject.status === "draft" ? apiFormObject.liveFormEditorStep : null;
  if (lastSavedDraftStep && isValidStepKey(lastSavedDraftStep)) {
    return lastSavedDraftStep;
  }

  return "title";
};

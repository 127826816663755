import { forwardRef, Ref, RefAttributes, useImperativeHandle, useCallback, useRef, type JSX } from "react";

import { FieldValues, useController, useWatch } from "react-hook-form";

import { getLabel, isInputRequired } from "../../../helpers/validators";

import { BaseAmountInput } from "./BaseAmountInput";
import { FormAmountInputProps, AmountInputHandle } from "./types";

import { useFormInputRefs } from "@/hooks/useFormInputRefs";
import { useValidators } from "@/hooks/useValidators";

function InternalFormAmountInput<T extends FieldValues>(
  {
    control,
    defaultValue,
    inputRef,
    name,
    onBlur,
    onChange,
    rules,
    shouldUnregister,
    noAsterisk,
    ...rest
  }: FormAmountInputProps<T>,
  ref: Ref<AmountInputHandle>
) {
  const watchedValue = useWatch({ control, name });
  const watchedDefaultValue = (watchedValue as number) || defaultValue;
  const baseAmountRef = useRef<AmountInputHandle>(null);

  const validators = useValidators(rules);
  const isRequired = isInputRequired(validators);
  const { field } = useController<T>({
    name,
    control,
    defaultValue: watchedDefaultValue as never,
    rules: validators,
    shouldUnregister,
  });

  const handleChange = useCallback(
    (centsValue: number | null) => {
      field.onChange(centsValue);
      onChange?.(centsValue);
    },
    [field, onChange]
  );

  const handleBlur = useCallback(() => {
    field.onBlur();
    onBlur?.();
  }, [field, onBlur]);

  useImperativeHandle(
    ref,
    () => ({
      editValue: (value: number) => {
        baseAmountRef.current?.editValue(value);
        const parsedValue = value || 0;
        if (parsedValue && parsedValue !== watchedValue) {
          field.onChange(parsedValue);
        }
      },
    }),
    [field, watchedValue]
  );

  const refCallBack = useFormInputRefs({ inputRefCallBack: field.ref, inputRef });

  return (
    <BaseAmountInput
      {...rest}
      defaultValue={watchedDefaultValue}
      inputRef={refCallBack}
      onChange={handleChange}
      ref={baseAmountRef}
      onBlur={handleBlur}
      label={getLabel(isRequired, rest.label, noAsterisk)}
      name={name}
    />
  );
}

InternalFormAmountInput.displayName = "FormAmountInput";

export const FormAmountInput = forwardRef(InternalFormAmountInput) as unknown as <T extends FieldValues>(
  props: FormAmountInputProps<T> & RefAttributes<AmountInputHandle>
) => JSX.Element;

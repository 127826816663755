import { createContext, useContext, useRef, useCallback, FC, PropsWithChildren } from "react";

import { BackgroundShapesHandle, BackgroundShapes } from "./BackgroundShapes";

interface ShapesContextType {
  rotate: (shapeId?: string) => void;
  drop: (shapeId?: string) => void;
  reverseRotate: (shapeId?: string) => void;
  up: (shapeId?: string) => void;
}

const ShapesContext = createContext<ShapesContextType>({
  rotate: () => {},
  drop: () => {},
  reverseRotate: () => {},
  up: () => {},
});

export const useShapes = (): ShapesContextType => {
  return useContext(ShapesContext);
};

export const ShapesProvider: FC<PropsWithChildren> = ({ children }) => {
  // Create a ref to access BackgroundShapes methods
  const shapesRef = useRef<BackgroundShapesHandle>(null);

  // Define the action functions
  const rotate = useCallback(() => {
    shapesRef.current?.rotate();
  }, []);

  const drop = useCallback(() => {
    shapesRef.current?.drop();
  }, []);

  const reverseRotate = useCallback(() => {
    shapesRef.current?.reverseRotate();
  }, []);

  const up = useCallback(() => {
    shapesRef.current?.up();
  }, []);

  const contextValue: ShapesContextType = {
    rotate,
    drop,
    reverseRotate,
    up,
  };

  return (
    <ShapesContext.Provider value={contextValue}>
      <BackgroundShapes ref={shapesRef} />
      {children}
    </ShapesContext.Provider>
  );
};

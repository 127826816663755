// Interface for animation steps
export interface AnimationStep {
  rotate?: number;
  translateY?: number;
  scale?: (shapeScaleMultiplier: number) => number;
  opacity?: number;
  duration: number;
}

// Configuration for animations, defining steps for each animation type
export interface AnimationConfig {
  steps: AnimationStep[];
}

// Configuration for animations, defining steps for each animation type
export const animationConfig: { [key: string]: AnimationConfig } = {
  rotateAndScale: {
    steps: [
      // Start with a small backward rotation
      {
        rotate: -12,
        duration: 200,
      },
      // Rotate forward with a larger rotation and apply scaling
      {
        rotate: 38,
        duration: 400,
        // Scale function that scales based on shapeScaleMultiplier
        scale: (shapeScaleMultiplier: number) => shapeScaleMultiplier * 1.06,
      },
      // Finish with a slight backward rotation to balance
      {
        rotate: -6,
        duration: 200,
      },
    ],
  },
  simpleRotate: {
    steps: [
      // Start with a small backward rotation
      {
        rotate: -12,
        duration: 200,
      },
      // Rotate forward with a larger rotation without scaling
      {
        rotate: 38,
        duration: 400,
      },
      // Finish with a slight backward rotation to balance
      {
        rotate: -6,
        duration: 200,
      },
    ],
  },
  reverseRotateAndScaleDown: {
    steps: [
      // Start with a slight forward rotation
      {
        rotate: 6,
        duration: 200,
      },
      // Rotate backward with a larger rotation and apply reverse scaling
      {
        rotate: -38,
        duration: 400,
        // Scale function that scales based on shapeScaleMultiplier
        scale: (shapeScaleMultiplier: number) => 1 / (shapeScaleMultiplier * 1.06),
      },
      // Finish with a slight forward rotation to balance
      {
        rotate: 12,
        duration: 200,
      },
    ],
  },
  simpleReverseRotate: {
    steps: [
      // Start with a slight forward rotation
      {
        rotate: 6,
        duration: 200,
      },
      // Rotate backward with a larger rotation without scaling
      {
        rotate: -38,
        duration: 400,
      },
      // Finish with a slight forward rotation to balance
      {
        rotate: 12,
        duration: 200,
      },
    ],
  },
  drop: {
    steps: [
      // Move shapes slightly upward to start
      {
        translateY: -50,
        duration: 200,
      },
      // Drop shapes down, slightly increase scale, and fade out
      {
        translateY: 700,
        scale: () => 1.05,
        opacity: 0,
        duration: 500,
      },
    ],
  },
  up: {
    steps: [
      // Move shapes back up, slightly decrease scale, and fade in
      {
        translateY: -650,
        scale: () => 1 / 1.05,
        opacity: 1,
        duration: 500,
      },
    ],
  },
};

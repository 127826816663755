import { useCallback, useMemo } from "react";

import { Controller, FieldValues, Path, PathValue, UseFormReturn } from "react-hook-form";

import { getChoiceDisabled } from "../../../../components/CustomAnswer/choices.helper";
import { CheckboxGroup } from "../../../../components/design-system/CheckboxGroup";
import { InputLabel } from "../../../../components/design-system/InputLabel";
import { getNestedErrorMessage } from "../../../../helpers/reactHookForm";
import { getLabel } from "../../../../helpers/validators";
import { useTranslate } from "../../../../hooks/useTranslate";

import { QuestionFieldChoice } from "./QuestionFieldChoice";
import { useAnswersOfParticipantsSelectedChoicesCount } from "./useAnswersOfParticipantsSelectedChoicesCount";

import { QuestionOutput } from "@/types/trpc";

export interface Props<T extends FieldValues> {
  form: UseFormReturn<T>;
  name: Path<T>;
  question: QuestionOutput;
  label: string;
  answersOfParticipantsSelectedChoicesCount?: Record<string, number>;
}

export function QuestionFieldMultipleCheckboxes<T extends FieldValues>({ question, form, name, label }: Props<T>) {
  const { t } = useTranslate();

  const { control, formState } = form;

  const sortedQuestionChoices = useMemo(
    () => question.choices?.slice().sort((choiceA, choiceB) => choiceA.sortIndex - choiceB.sortIndex) || [],
    [question.choices]
  );

  const choiceIdsName = `${name}.choiceIds` as Path<T>;

  const rules = {
    validate: {
      validateRequiredQuestion: (value: string[]) => {
        return !question.required
          ? true
          : (value && Array.isArray(value) && value.length > 0) || t("common", "answerRequired");
      },
    },
  };

  // Handle group-level changes by transforming Record to string[]
  const handleGroupChange = useCallback(
    (updatedValues: Record<string, boolean>) => {
      const selected = Object.keys(updatedValues).filter((key) => updatedValues[key]);
      form.setValue(choiceIdsName, selected as PathValue<T, Path<T>>, { shouldValidate: true });
    },
    [choiceIdsName, form]
  );

  const answersOfParticipantsSelectedChoicesCount = useAnswersOfParticipantsSelectedChoicesCount(form);

  const choicesErrorMessage = getNestedErrorMessage(choiceIdsName, formState);

  return (
    <InputLabel
      errorMessage={choicesErrorMessage}
      label={getLabel(question.required, label)}
      data-test="question-field-multiple-checkboxes"
    >
      <Controller
        control={control}
        name={choiceIdsName}
        rules={rules}
        render={({ field: { value } }: { field: { value: Array<string> } }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const valuesAsRecord: Record<string, boolean> = useMemo(() => {
            const record: Record<string, boolean> = {};
            sortedQuestionChoices.forEach((choice) => {
              record[choice.id] = value?.includes(choice.id) || false;
            });
            return record;
          }, [value]);

          return (
            <CheckboxGroup
              name={choiceIdsName}
              values={valuesAsRecord}
              onChange={handleGroupChange}
              checkboxes={sortedQuestionChoices.map((choice) => ({
                fullWidth: true,
                label: (
                  <QuestionFieldChoice
                    choice={choice}
                    answersOfParticipantsSelectedChoicesCount={answersOfParticipantsSelectedChoicesCount}
                  />
                ),
                choice: choice.id,
                disabled:
                  !value?.includes(choice.id) && getChoiceDisabled(choice, answersOfParticipantsSelectedChoicesCount),
              }))}
            />
          );
        }}
      />
    </InputLabel>
  );
}

import { ReactNode, useMemo } from "react";

import { useMediaQuery } from "../../../../hooks/useMediaQuery";

import ShapeDesktop1 from "@/icons/shapes/shape-desktop-1.svg";
import ShapeDesktop2 from "@/icons/shapes/shape-desktop-2.svg";
import ShapeDesktop3 from "@/icons/shapes/shape-desktop-3.svg";
import ShapeDesktop4 from "@/icons/shapes/shape-desktop-4.svg";
import ShapeMobile1 from "@/icons/shapes/shape-mobile-1.svg";
import ShapeMobile2 from "@/icons/shapes/shape-mobile-2.svg";
import ShapeMobile3 from "@/icons/shapes/shape-mobile-3.svg";
import ShapeMobile4 from "@/icons/shapes/shape-mobile-4.svg";

interface ShapeConfig {
  id: string;
  component: ReactNode;
  style: object;
  rotationMultiplier: number;
  scaleMultiplier: number;
}

export const useShapesConfig = () => {
  const { isSmallScreen } = useMediaQuery();

  return useMemo<ShapeConfig[]>(() => {
    if (isSmallScreen) {
      return [
        {
          // Shape 1 - Top Left
          id: "mobileShape1",
          component: <ShapeMobile1 />,
          style: { top: "-64px", left: "-40px", width: "193px", height: "auto" },
          rotationMultiplier: 1.0,
          scaleMultiplier: 1.3,
        },
        {
          // Shape 2 - Bottom Right
          id: "mobileShape2",
          component: <ShapeMobile2 />,
          style: { bottom: "-16px", right: "-24px", width: "96px", height: "auto" },
          rotationMultiplier: 0.9,
          scaleMultiplier: 1.5,
        },
        {
          // Shape 3 - Top Right
          id: "mobileShape3",
          component: <ShapeMobile3 />,
          style: { top: "35%", right: "-108px", width: "186px", height: "auto" },
          rotationMultiplier: 1.1,
          scaleMultiplier: 0.8,
        },
        {
          // Shape 4 - Bottom Left
          id: "mobileShape4",
          component: <ShapeMobile4 />,
          style: { bottom: "64px", left: "-80px", width: "133px", height: "auto" },
          rotationMultiplier: 1.0,
          scaleMultiplier: 1.05,
        },
      ];
    }

    return [
      {
        // Shape 1 - Top Left
        id: "desktopShape1",
        component: <ShapeDesktop1 />,
        style: {
          top: "-64px",
          left: "-80px",
          width: "258px",
          height: "auto",
        },
        rotationMultiplier: 1.0,
        scaleMultiplier: 1.1,
      },
      {
        // Shape 4 - Top Right
        id: "desktopShape2",
        component: <ShapeDesktop4 />,
        style: {
          top: "-156px",
          right: "-72px",
          width: "340px",
          height: "auto",
        },
        rotationMultiplier: 1.05,
        scaleMultiplier: 1.05,
      },
      {
        // Shape 3 - Bottom Left
        id: "desktopShape3",
        component: <ShapeDesktop3 />,
        style: {
          bottom: "40px",
          left: "-120px",
          width: "264px",
          height: "auto",
        },
        rotationMultiplier: 0.95,
        scaleMultiplier: 1.1,
      },
      {
        // Shape 2 - Bottom Right
        id: "desktopShape4",
        component: <ShapeDesktop2 />,
        style: {
          bottom: "-24px",
          right: "-120px",
          width: "264px",
          height: "auto",
        },
        rotationMultiplier: 1.0,
        scaleMultiplier: 1.05,
      },
    ];
  }, [isSmallScreen]);
};

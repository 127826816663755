import { forwardRef, useImperativeHandle, useRef, useEffect, useState, useMemo, useContext } from "react";

import { useTheme, Box } from "@mui/material";

import { FrontendFormContext } from "../../../../contexts/FrontendFormContext";
import { GHOST_PREVIEW_OPACITY } from "../../../LiveFormEditor/LivePreview/ghostPreview.constant";
import { useIsGhostPreview } from "../../../LiveFormEditor/LivePreview/useIsGhostPreview";

import { AnimationConfig, animationConfig } from "./BackgroundShapes.config";
import { useAnimationQueue } from "./useAnimationQueue";
import { useShapesConfig } from "./useShapesConfig";

// Interface representing the transformation state of each shape
interface ShapeTransform {
  rotate: number;
  translateY: number;
  scale: number;
  opacity: number;
}

// Interface defining the methods exposed via ref
export interface BackgroundShapesHandle {
  rotate: () => void;
  reverseRotate: () => void;
  drop: () => void;
  up: () => void;
}

export const BackgroundShapes = forwardRef<BackgroundShapesHandle>((props, ref) => {
  const theme = useTheme();
  const { isEmbed } = useContext(FrontendFormContext);

  const { enqueueAnimation, onAnimationComplete, isAnimatingRef } = useAnimationQueue({
    maxQueueLength: 1,
  });
  const isGhostPreview = useIsGhostPreview("BackgroundShapes");

  const shapes = useShapesConfig();
  const shapeIds = useMemo(() => shapes.map((shape) => shape.id), [shapes]);

  // Initialize transformation states for all shapes
  const [transforms, setTransforms] = useState<{ [shapeId: string]: ShapeTransform }>({});

  const [transitionDuration, setTransitionDuration] = useState<string>("transform 200ms ease, opacity 200ms ease");

  const timeoutsRef = useRef<NodeJS.Timeout[]>([]);
  const rotationIndexRef = useRef<number>(0);
  const isDroppedRef = useRef<boolean>(false); // Track if shapes are dropped

  // Synchronize transforms state with shapes when size screen changes
  useEffect(() => {
    setTransforms((prevTransforms) => {
      const newTransforms: { [shapeId: string]: ShapeTransform } = { ...prevTransforms };

      let hasChanges = false;

      shapes.forEach((shape) => {
        if (!prevTransforms[shape.id]) {
          // Initialize new shape transform
          newTransforms[shape.id] = {
            rotate: 0,
            translateY: 0,
            scale: 1,
            opacity: 1,
          };
          hasChanges = true;
        }
      });

      // Remove transforms for shapes that no longer exist
      Object.keys(prevTransforms).forEach((id) => {
        if (!shapeIds.includes(id)) {
          delete newTransforms[id];
          hasChanges = true;
        }
      });

      return hasChanges ? newTransforms : prevTransforms;
    });
  }, [shapes, shapeIds]);

  // Clear active timeouts
  useEffect(() => {
    const timeouts = timeoutsRef.current;

    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, []);

  const executeAnimation = (animation: AnimationConfig) => {
    // Mark that an animation sequence is currently running
    isAnimatingRef.current = true;
    const { steps } = animation;

    // Iterate over each animation step
    steps.forEach((step, index) => {
      // Calculate the cumulative delay for the current step by summing the durations of all previous steps
      const delay = steps
        .slice(0, index) // Get all steps before the current one
        .reduce((acc, curr) => acc + curr.duration, 0); // Sum their durations

      // Schedule the execution of the current step after the calculated delay
      const timeout = setTimeout(() => {
        // Update the CSS transition duration based on the current step's duration
        setTransitionDuration(`transform ${step.duration}ms ease, opacity ${step.duration}ms ease`);

        // Update the transformation states for all shapes
        setTransforms((prev) => {
          // Create a shallow copy of the previous transforms to avoid direct state mutation
          const updated = { ...prev };

          // Iterate over each shape by its ID
          shapeIds.forEach((id) => {
            // Find the corresponding shape configuration
            const shape = shapes.find((s) => s.id === id);
            if (!shape) {
              // If the shape is not found, skip to the next iteration
              return;
            }

            // Retrieve the previous transformation state for the shape or initialize it if not present
            const prevTransform = prev[id] || {
              rotate: 0,
              translateY: 0,
              scale: 1,
              opacity: 1,
            };

            // Apply the rotation multiplier specific to the shape
            const rotationMultiplier = shape.rotationMultiplier || 1;

            // Initialize the new scale based on the previous scale
            let scale = prevTransform.scale;

            // If the current step includes a scaling transformation, apply it
            if (step.scale) {
              // The scale function takes the shape's scale multiplier and returns the new scale
              scale *= step.scale(shape.scaleMultiplier || 1);
            }

            // Update the transformation state for the current shape
            updated[id] = {
              // Increment the rotation by the step's rotate value multiplied by the rotation multiplier
              rotate: prevTransform.rotate + (step.rotate || 0) * rotationMultiplier,
              // Increment the vertical translation by the step's translateY value
              translateY: prevTransform.translateY + (step.translateY || 0),
              scale,
              // Update the opacity if the step specifies it; otherwise, retain the previous opacity
              opacity: step.opacity !== undefined ? step.opacity : prevTransform.opacity,
            };
          });

          // Return the updated transformation states to update the component's state
          return updated;
        });

        // If this is the last step in the sequence, schedule the callback to indicate animation completion
        if (index === steps.length - 1) {
          // Schedule the onAnimationComplete callback after the current step's duration
          const finalTimeout = setTimeout(() => {
            onAnimationComplete();
          }, step.duration);

          // Store the timeout ID for potential cleanup
          timeoutsRef.current.push(finalTimeout);
        }
      }, delay); // Execute the step after the cumulative delay

      // Store the timeout ID for potential cleanup
      timeoutsRef.current.push(timeout);
    });
  };

  useImperativeHandle(ref, () => ({
    rotate: () => {
      // If rotate from initial position, use rotateAndScale animation, otherwise use simpleRotate
      const animationKey = rotationIndexRef.current === 0 ? "rotateAndScale" : "simpleRotate";
      const animation = animationConfig[animationKey];
      rotationIndexRef.current += 1;
      const animate = () => executeAnimation(animation);
      enqueueAnimation(animate);
    },
    reverseRotate: () => {
      // If reverseRotate to initial position, use reverseRotateAndScaleDown animation, otherwise use simpleReverseRotate
      const animationKey = rotationIndexRef.current === 1 ? "reverseRotateAndScaleDown" : "simpleReverseRotate";
      const animation = animationConfig[animationKey];
      rotationIndexRef.current -= 1;
      const animate = () => executeAnimation(animation);
      enqueueAnimation(animate);
    },
    drop: () => {
      // Safety net: Allow drop only if shapes are in the "up" position
      if (isDroppedRef.current) {
        return;
      }
      isDroppedRef.current = true; // Update state to indicate shapes are dropped
      const animate = () => executeAnimation(animationConfig.drop);
      enqueueAnimation(animate);
    },
    up: () => {
      // Safety net: Allow up only if shapes have been dropped
      if (!isDroppedRef.current) {
        return;
      }
      isDroppedRef.current = false; // Update state to indicate shapes are back up
      const animate = () => executeAnimation(animationConfig.up);
      enqueueAnimation(animate);
    },
  }));

  const getShapeStyle = (shapeId: string, base: object) => {
    const shapeTransform = transforms[shapeId] || {
      rotate: 0,
      translateY: 0,
      scale: 1,
      opacity: 1,
    };

    const transformString = `translateY(${shapeTransform.translateY}px) rotate(${shapeTransform.rotate}deg) scale(${shapeTransform.scale})`;

    return {
      ...base,
      transform: transformString,
      opacity: shapeTransform.opacity,
      transition: transitionDuration,
    };
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        zIndex: -1,
        pointerEvents: "none",
        backgroundColor: theme.palette.surface.form.supershy,

        ...(isGhostPreview && {
          opacity: GHOST_PREVIEW_OPACITY,
        }),
      }}
      data-test="background-shapes"
    >
      {!isEmbed &&
        shapes.map((shape) => (
          <Box
            key={shape.id}
            sx={getShapeStyle(shape.id, {
              position: "absolute",
              fill: theme.palette.surface.form.quiet,
              ...shape.style,
            })}
            data-test={`shape-${shape.id}`}
          >
            {shape.component}
          </Box>
        ))}
    </Box>
  );
});

BackgroundShapes.displayName = "BackgroundShapes";

import { FC, createContext, PropsWithChildren, useState } from "react";

export interface HeartsAnimationContextType {
  shouldDisplayHeartsAnimation: boolean;
  animationKey?: string;
  triggerHeartsAnimation: () => void;
}

export const HeartsAnimationContext = createContext<HeartsAnimationContextType>({
  shouldDisplayHeartsAnimation: false,
  triggerHeartsAnimation: () => {},
});

export const HeartsAnimationProvider: FC<PropsWithChildren> = ({ children }) => {
  // Counter tracks how many animations are currently running
  const [animationCounter, setAnimationCounter] = useState(0);

  const triggerHeartsAnimation = () => {
    // Increment counter when new animation starts
    setAnimationCounter((prev) => prev + 1);
  };

  const contextValue = {
    // Show animation as long as at least one is running
    shouldDisplayHeartsAnimation: animationCounter > 0,
    triggerHeartsAnimation,
    animationKey: `animation-key-${animationCounter}`,
  };

  return <HeartsAnimationContext.Provider value={contextValue}>{children}</HeartsAnimationContext.Provider>;
};

import { forwardRef, Ref, RefAttributes, type JSX } from "react";

import { FieldValues } from "react-hook-form";

import { BaseAmountInput } from "./BaseAmountInput";
import { FormAmountInput } from "./FormAmountInput";
import { AmountInputHandle, AmountInputProps } from "./types";

function AmountInputInner<T extends FieldValues>(
  { control, name, rules, shouldUnregister, ...rest }: AmountInputProps<T>,
  ref: Ref<AmountInputHandle>
) {
  return control && name ? (
    <FormAmountInput
      {...rest}
      ref={ref}
      control={control}
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  ) : (
    <BaseAmountInput {...rest} name={name} ref={ref} />
  );
}

export const AmountInput = forwardRef(AmountInputInner) as unknown as <T extends FieldValues>(
  props: AmountInputProps<T> & RefAttributes<AmountInputHandle>
) => JSX.Element;

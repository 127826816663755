import { MembershipValidityPeriod } from "../enums/ticketing";
import { getDatePlusNMonths, getDatePlusNYears, getNextOccurrenceWithThreeMonthGracePeriod } from "./dates";
export const getMembershipExpirationDate = ({ membershipValidityPeriod, expirationDate, paymentDate, }) => {
    if (membershipValidityPeriod === MembershipValidityPeriod.OneYearAfterPayment) {
        return getDatePlusNYears(paymentDate || new Date(), 1);
    }
    else if (membershipValidityPeriod === MembershipValidityPeriod.OneMonthAfterPayment) {
        return getDatePlusNMonths(paymentDate || new Date(), 1);
    }
    else if (membershipValidityPeriod === MembershipValidityPeriod.AtDate && expirationDate) {
        return getNextOccurrenceWithThreeMonthGracePeriod(new Date(expirationDate));
    }
    return null;
};

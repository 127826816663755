import * as React from "react";
import { FunctionComponent, PropsWithChildren, useContext, useState } from "react";

interface UserInputContextValues {
  userInputs: Record<string, string>[];
  addUserInput: (userInput: Record<string, string>) => void;
}

const defaultValues: UserInputContextValues = {
  userInputs: [],
  addUserInput: () => {},
};

export const UserInputContext = React.createContext<UserInputContextValues>(defaultValues);

export const useUserInputContext = (): UserInputContextValues => {
  return useContext(UserInputContext);
};

export const UserInputProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [userInputs, setUserInputs] = useState<Record<string, string>[]>([]);

  const addUserInput = (userInput: Record<string, string>) => {
    setUserInputs([...userInputs, userInput]);
  };

  return (
    <UserInputContext.Provider
      value={{
        userInputs,
        addUserInput,
      }}
    >
      {children}
    </UserInputContext.Provider>
  );
};

import { FC } from "react";

import { Icon, IconProps } from "../../../../components/design-system/Icon";
import { Tooltip, TooltipProps } from "../../../../components/design-system/Tooltip";

import { Info as InfoIcon } from "@/icons/outlined";

type InfoIconWithTooltipProps = {
  tooltip: string;
  tooltipProps?: TooltipProps;
  iconProps?: IconProps;
};

export const InfoIconWithTooltip: FC<InfoIconWithTooltipProps> = ({ tooltip, tooltipProps, iconProps }) => {
  return (
    <Tooltip title={tooltip} {...tooltipProps}>
      <Icon size="large" {...iconProps}>
        <InfoIcon />
      </Icon>
    </Tooltip>
  );
};

import { ReactElement, useMemo, forwardRef, Ref, useImperativeHandle, useState, useCallback, ChangeEvent } from "react";

import { InputBaseComponentProps } from "@mui/material/InputBase";
import { Locales, formatCurrency, getDollarValue, countDecimals } from "@simplyk/common";

import { TextField } from "../TextField";

import { parseDefaultDisplayValue, parseInputValue } from "./helpers";
import { BaseAmountInputProps, AmountInputHandle } from "./types";

const InternalBaseAmountInput = (
  {
    classes,
    currency,
    debounced,
    defaultValue,
    inputProps,
    locale,
    onBlur,
    onChange,
    placeholder,
    name,
    endAdornment,
    currencyPlacement = "end",
    ...rest
  }: BaseAmountInputProps,
  ref: Ref<AmountInputHandle>
): ReactElement<unknown> => {
  const currentPlaceholder = placeholder || (locale === Locales.EN ? "0.00" : "0,00");

  const displayedDefaultValue = parseDefaultDisplayValue(defaultValue);
  const [displayedValue, setDisplayedValue] = useState<string>(displayedDefaultValue);

  const setValue = useCallback(
    (centsValue: number | null) => {
      onChange?.(centsValue);
    },
    [onChange]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(parseInputValue(event.target.value));
      if (!debounced) {
        setDisplayedValue(event.target.value);
      }
    },
    [debounced, setValue]
  );

  const handleChangeNotDebounced = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDisplayedValue(event.target.value);
  }, []);

  const handleBlur = useCallback(() => {
    setDisplayedValue((value) => (countDecimals(Number(value)) > 2 ? parseFloat(value).toFixed(2) : value));
    onBlur?.();
  }, [onBlur]);

  const currentInputProps: InputBaseComponentProps = useMemo(
    () => ({
      ...inputProps,
      type: "number",
      min: 0,
      inputMode: "decimal",
      step: "any",
      onWheel: (event) => {
        event.currentTarget.blur();
      },
      onKeyPress: (event) => {
        if (event.code === "Minus") {
          event.preventDefault();
        }
      },
    }),
    [inputProps]
  );

  useImperativeHandle(
    ref,
    () => ({
      editValue: (value: number) => {
        const currentInputValue = getDollarValue(parseFloat(displayedValue) || 0);
        if (value && value !== currentInputValue) {
          setDisplayedValue(parseDefaultDisplayValue(value));
        }
      },
    }),
    [displayedValue]
  );

  const { currency: classesCurrency, ...classesRest } = classes || { currency: undefined };

  const displayedCurrency = <span className={classesCurrency}>{formatCurrency(locale, currency)}</span>;

  return (
    <TextField
      {...rest}
      classes={classesRest}
      debounced={debounced}
      startAdornment={currencyPlacement === "start" ? displayedCurrency : undefined}
      endAdornment={endAdornment ? endAdornment : currencyPlacement === "end" ? displayedCurrency : undefined}
      inputProps={currentInputProps}
      onChange={handleChange}
      onChangeNotDebounced={handleChangeNotDebounced}
      placeholder={currentPlaceholder}
      ref={ref as Ref<HTMLInputElement>}
      value={displayedValue}
      onBlur={handleBlur}
      type="number"
      name={name}
    />
  );
};

export const BaseAmountInput = forwardRef(InternalBaseAmountInput);

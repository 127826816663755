import React, { createContext, useState, useContext, useRef, RefObject } from "react";

import { DonationFormCategory, TicketingFormCategory } from "@simplyk/common";

import { useIsOnboarding } from "../../../hooks/useIsOnboarding";
import { useStepNavigation } from "../Navigation/useStepNavigation";
import { LiveEditorStep, LiveEditorStepKey } from "../Steps/LiveEditorStep.enum";

import { useTrackAndSaveMostAdvancedStepReached } from "@/features/LiveFormEditor/Navigation/useTrackAndSaveMostAdvancedStepReached";
import { LiveDonationData, LiveTicketingData } from "@/features/LiveFormEditor/types/LiveFormEditor.types";
import { DonationFormOutput, TicketingOutput } from "@/types/trpc";

export type PreviewType = "form" | "email" | "e-ticket";

export type SaveFormModalState = {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  closeModalAndExecuteActions: () => void;
};

export type ShareFormModalState = {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
};

export type FormType = "ticketing" | "donation-form";

export interface LiveFormEditorContextType<FormTypeDiscriminator extends FormType = FormType> {
  currentStepIndex: LiveEditorStep;
  currentStepKey: LiveEditorStepKey;
  updateCurrentStep: (stepKey: LiveEditorStepKey) => void;
  totalNumberOfSteps: number;
  liveEditorStepsIndexes: LiveEditorStep[];
  liveEditorStepsKeys: LiveEditorStepKey[];
  getIndexStepFromKey: (key: LiveEditorStepKey) => LiveEditorStep;
  getKeyStepFromIndex: (index: LiveEditorStep) => LiveEditorStepKey;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  mostAdvancedStepReached: LiveEditorStep;
  apiFormObject: FormTypeDiscriminator extends "ticketing" ? TicketingOutput : DonationFormOutput;
  updateApiFormObject: (
    formObject: FormTypeDiscriminator extends "ticketing" ? TicketingOutput : DonationFormOutput
  ) => void;
  liveFormData?: FormTypeDiscriminator extends "ticketing" ? LiveTicketingData : LiveDonationData;
  setLiveFormData: (data: FormTypeDiscriminator extends "ticketing" ? LiveTicketingData : LiveDonationData) => void;
  previewDevice: "mobile" | "desktop";
  setPreviewDevice: (mode: "mobile" | "desktop") => void;
  setRefreshArrowPosition: (callback: () => void) => void;
  refreshArrowPosition: () => void;
  iframeRef: React.RefObject<HTMLIFrameElement | null>;
  previewType: PreviewType;
  saveCurrentStep: RefObject<() => Promise<void>>;
  isStepChanging: boolean;
  setIsStepChanging: (isStepChanging: boolean) => void;
  editOrCreateMode: "edit" | "create";
  setEditOrCreateMode: (mode: "edit" | "create") => void;
  checkIfFormIsDirty: RefObject<() => boolean>;
  resetForm: RefObject<() => void>;
  isNavigationOpen: boolean;
  setIsNavigationOpen: (isNavigationOpen: boolean) => void;
  isOnboarding: boolean;
  saveFormModalState: SaveFormModalState;
  shareFormModalState: ShareFormModalState;
  askSaveIfDirtyAndExecute: (callback: () => void) => void;
  formType: FormTypeDiscriminator extends "ticketing" ? "ticketing" : "donation-form";
  formCategory: FormTypeDiscriminator extends "ticketing" ? TicketingFormCategory : DonationFormCategory;
}

const defaultValues: LiveFormEditorContextType<"ticketing"> = {
  currentStepIndex: 0,
  currentStepKey: "title",
  updateCurrentStep: () => {},
  totalNumberOfSteps: 0,
  liveEditorStepsIndexes: [],
  liveEditorStepsKeys: [],
  goToNextStep: () => {},
  goToPreviousStep: () => {},
  mostAdvancedStepReached: 1,
  getIndexStepFromKey: () => 0,
  getKeyStepFromIndex: () => "title",
  apiFormObject: {} as TicketingOutput,
  updateApiFormObject: () => {},
  liveFormData: undefined,
  setLiveFormData: () => {},
  previewDevice: "mobile",
  setPreviewDevice: () => {},
  setRefreshArrowPosition: () => {},
  refreshArrowPosition: () => {},
  iframeRef: { current: null },
  previewType: "form",
  saveCurrentStep: { current: () => Promise.resolve() },
  isStepChanging: false,
  setIsStepChanging: () => {},
  editOrCreateMode: "edit",
  setEditOrCreateMode: () => {},
  saveFormModalState: {
    isOpen: false,
    openModal: () => {},
    closeModal: () => {},
    closeModalAndExecuteActions: () => {},
  },
  shareFormModalState: {
    isOpen: false,
    openModal: () => {},
    closeModal: () => {},
  },
  checkIfFormIsDirty: { current: () => false },
  resetForm: { current: () => {} },
  isNavigationOpen: false,
  setIsNavigationOpen: () => {},
  isOnboarding: false,
  askSaveIfDirtyAndExecute: () => {},
  formType: "ticketing",
  formCategory: TicketingFormCategory.Event,
};

export const LiveFormEditorContext = createContext<LiveFormEditorContextType<FormType>>(
  defaultValues as LiveFormEditorContextType<FormType>
);

interface LiveFormEditorProviderProps<F extends FormType> {
  formType: F;
  steps: { key: LiveEditorStepKey; shouldBeSkip?: boolean }[];
  liveFormData: F extends "ticketing" ? LiveTicketingData | null : LiveDonationData | null;
  apiFormObject: F extends "ticketing" ? TicketingOutput : DonationFormOutput;
  editOrCreateMode: "edit" | "create";
  setLiveFormData: (data: F extends "ticketing" ? LiveTicketingData : LiveDonationData) => void;
  setApiFormObject: (formObject: F extends "ticketing" ? TicketingOutput : DonationFormOutput) => void;
  setEditOrCreateMode: (mode: "edit" | "create") => void;
  children: (params: LiveFormEditorContextType<F>) => React.ReactNode;
  formCategory: F extends "ticketing" ? TicketingFormCategory : DonationFormCategory;
}

export const LiveFormEditorProvider = <F extends FormType>({
  children,
  steps,
  liveFormData,
  apiFormObject,
  editOrCreateMode,
  setLiveFormData,
  setApiFormObject,
  setEditOrCreateMode,
  formType,
  formCategory,
}: LiveFormEditorProviderProps<F>) => {
  const {
    currentStepIndex,
    currentStepKey,
    updateCurrentStep,
    goToNextStep,
    goToPreviousStep,
    isStepChanging,
    setIsStepChanging,
    getIndexStepFromKey,
    getKeyStepFromIndex,
    liveEditorStepsKeys,
    liveEditorStepsIndexes,
  } = useStepNavigation(steps, apiFormObject);
  const { mostAdvancedStepReached } = useTrackAndSaveMostAdvancedStepReached({
    currentStepIndex,
    apiFormObject,
    currentStepKey,
    getIndexStepFromKey,
    getKeyStepFromIndex,
    formType,
  });

  const [previewDevice, setPreviewDevice] = useState<"mobile" | "desktop">("mobile");
  const [refreshArrowPosition, setRefreshArrowPosition] = useState<() => void>(() => {});
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const saveCurrentStep = useRef<() => Promise<void>>(() => Promise.resolve());
  const checkIfFormIsDirty = useRef<() => boolean>(() => false);
  const resetForm = useRef<() => void>(() => {});

  const [isNavigationOpen, setIsNavigationOpen] = useState(true);

  const previewType = getPreviewType(currentStepKey);

  const totalNumberOfSteps = liveEditorStepsKeys.length;

  const { isOnboarding } = useIsOnboarding();

  const [isSaveFormModalOpen, setIsSaveFormModalOpen] = useState(false);
  const saveCallbackRef = useRef<() => void>(() => {});
  const saveFormModalState: SaveFormModalState = {
    isOpen: isSaveFormModalOpen,
    openModal: () => setIsSaveFormModalOpen(true),
    closeModal: () => setIsSaveFormModalOpen(false),
    closeModalAndExecuteActions: () => {
      setIsSaveFormModalOpen(false);
      saveCallbackRef.current();
      saveCallbackRef.current = () => {};
    },
  };
  const askSaveIfDirtyAndExecute = (callback: () => void) => {
    if (checkIfFormIsDirty.current()) {
      saveCallbackRef.current = callback;
      setIsSaveFormModalOpen(true);
    } else {
      callback();
    }
  };

  const [isShareFormModalOpen, setIsShareFormModalOpen] = useState(false);
  const shareFormModalState: ShareFormModalState = {
    isOpen: isShareFormModalOpen,
    openModal: () => setIsShareFormModalOpen(true),
    closeModal: () => setIsShareFormModalOpen(false),
  };

  const contextValue: LiveFormEditorContextType<F> = {
    currentStepIndex,
    currentStepKey,
    updateCurrentStep,
    goToNextStep,
    goToPreviousStep,
    mostAdvancedStepReached,
    liveEditorStepsIndexes,
    liveEditorStepsKeys,
    apiFormObject,
    updateApiFormObject: setApiFormObject,
    liveFormData: liveFormData as F extends "ticketing" ? LiveTicketingData : LiveDonationData,
    setLiveFormData,
    previewDevice,
    setPreviewDevice,
    setRefreshArrowPosition,
    refreshArrowPosition,
    iframeRef,
    previewType,
    saveCurrentStep,
    isStepChanging,
    setIsStepChanging,
    editOrCreateMode,
    setEditOrCreateMode,
    saveFormModalState,
    shareFormModalState,
    checkIfFormIsDirty,
    resetForm,
    isNavigationOpen,
    setIsNavigationOpen,
    totalNumberOfSteps,
    isOnboarding,
    askSaveIfDirtyAndExecute,
    getIndexStepFromKey,
    getKeyStepFromIndex,
    formType: formType as never,
    formCategory,
  };

  return <LiveFormEditorContext.Provider value={contextValue}>{children(contextValue)}</LiveFormEditorContext.Provider>;
};

export const useLiveFormEditorContext = <F extends FormType>() => {
  return useContext(LiveFormEditorContext) as LiveFormEditorContextType<F>;
};

const getPreviewType = (currentStepKey: LiveEditorStepKey): PreviewType => {
  if (currentStepKey === "thank-you-email") {
    return "email";
  }
  if (currentStepKey === "e-ticket") {
    return "e-ticket";
  }
  return "form";
};

import { useState, useCallback, forwardRef } from "react";

import { OrganizationCountry, PhoneInputCountry } from "@simplyk/common";
import classNames from "classnames";

import { useCurrentInputVibe } from "../../../hooks/useCurrentInputVibe";
import { InputLabel } from "../InputLabel/InputLabel";
import { useMergeClasses } from "../useMergeClasses";

import { zeffyPhoneNumberClasses, StyledPhoneInput } from "./styles";
import { BasePhoneNumberInputProps } from "./types";

import { useCustomError } from "@/hooks/useCustomError";
import { useCustomInputProps } from "@/hooks/useCustomInputProps";

export const BasePhoneNumberInput = forwardRef<HTMLInputElement, BasePhoneNumberInputProps>(
  (
    {
      classes: externalClasses,
      organizationCountry,
      disableAutocomplete,
      error,
      errorMessage,
      inputRef,
      label,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      name,
      defaultValue,
      "data-test": dataTest,
      onlyCountries,
    },
    ref
  ) => {
    const currentInputVibe = useCurrentInputVibe();
    const classes = useMergeClasses(zeffyPhoneNumberClasses, externalClasses);

    const [isFocused, setIsFocused] = useState(false);

    const currentError = useCustomError(error, errorMessage);

    const customInputProps = useCustomInputProps({
      disableAutocomplete,
      dataTest,
      extraProps: { ref: inputRef, name },
    });

    const handleBlur = useCallback(() => {
      setIsFocused(false);
      onBlur?.();
    }, [onBlur]);

    const handleFocus = useCallback(() => {
      onFocus?.();
      setIsFocused(true);
    }, [onFocus]);

    return (
      <InputLabel
        ref={ref}
        label={label}
        vibe={currentInputVibe}
        errorMessage={errorMessage}
        data-test={`${dataTest}-label`}
      >
        <StyledPhoneInput
          country={organizationCountry === OrganizationCountry.Canada ? PhoneInputCountry.CA : PhoneInputCountry.US}
          onlyCountries={onlyCountries}
          containerClass={classNames(classes.default, {
            [classes.containerForm]: currentInputVibe === "form",
            [classes.focus]: isFocused,
            [classes.error]: currentError,
          })}
          buttonClass={classes.button}
          inputClass={classes.input}
          specialLabel={(label as string) || ""}
          onBlur={handleBlur}
          onChange={onChange}
          onFocus={handleFocus}
          onKeyDown={onKeyDown}
          isValid={!currentError}
          inputProps={customInputProps}
          value={defaultValue}
          disableCountryGuess={true}
        />
      </InputLabel>
    );
  }
);

BasePhoneNumberInput.displayName = "BasePhoneNumberInput";

import { useCallback, useContext, useEffect } from "react";

import { CommandLang, CommandSource, CommandStatus, FormType, getInteger } from "@simplyk/common";
import { inferRouterInputs } from "@trpc/server";

import { useDebounce } from "../components/TextField/useDebounce";
import { isTest } from "../constants/env";
import { FrontendFormContext } from "../contexts/FrontendFormContext";
import { useLocaleContext } from "../contexts/LocaleContext";
import { SessionContext } from "../contexts/SessionContext";
import { AppRouterType, trpc } from "../helpers/trpc";

import { NumberConstant } from "@/constants/number";
type ZodUpsertCommandInput = inferRouterInputs<AppRouterType>["upsertCommand"];

type UpsertCommandParams = Omit<ZodUpsertCommandInput, "id" | "sessionId">;

export const useUpsertCommand = (props: UpsertCommandParams, isDirty: boolean, shouldCancel: boolean) => {
  const { commandId, formType, formData, organization, isSubmitting } = useContext(FrontendFormContext);
  const { sessionId } = useContext(SessionContext);

  const { locale } = useLocaleContext();

  const { mutateAsync: upsertCommand } = trpc.upsertCommand.useMutation();

  const onUpdateCallback = useCallback(
    async (params: UpsertCommandParams) => {
      if (isSubmitting || !isDirty || !formType || !formData) {
        return;
      }
      const { totalAmount, productsAmount, tipAmount, tipPercentage, eligibleAmount, discountAmount, extraDonation } =
        params;
      if (
        (totalAmount && totalAmount >= NumberConstant.GRAPHQL_MAX_INT) ||
        (productsAmount && productsAmount >= NumberConstant.GRAPHQL_MAX_INT) ||
        (tipAmount && tipAmount > NumberConstant.GRAPHQL_MAX_INT) ||
        (eligibleAmount && eligibleAmount >= NumberConstant.GRAPHQL_MAX_INT) ||
        (discountAmount && discountAmount >= NumberConstant.GRAPHQL_MAX_INT) ||
        (extraDonation && extraDonation >= NumberConstant.GRAPHQL_MAX_INT)
      ) {
        return;
      }

      await upsertCommand({
        ...params,
        id: commandId,
        status: CommandStatus.Open,
        formType: FormType[formType as keyof typeof FormType],
        formId: formData.id,
        formLang: CommandLang[locale as keyof typeof CommandLang],
        source: CommandSource.FormSubmission,
        locale,
        organizationId: organization.id,
        extraDonation: extraDonation !== null && extraDonation !== undefined ? getInteger(extraDonation) : null,
        discountAmount:
          discountAmount !== null && discountAmount !== undefined ? getInteger(discountAmount as number) : null,
        eligibleAmount:
          eligibleAmount !== null && eligibleAmount !== undefined ? getInteger(eligibleAmount as number) : null,
        productsAmount:
          productsAmount !== null && productsAmount !== undefined ? getInteger(productsAmount as number) : 0,
        tipAmount: tipAmount !== null && tipAmount !== undefined ? getInteger(tipAmount as number) : 0,
        tipPercentage: tipPercentage ?? 0,
        totalAmount: totalAmount !== null && totalAmount !== undefined ? getInteger(totalAmount as number) : 0,
        sessionId,
        isCorporate: Boolean(params.isCorporate),
        isFormV2: params.isFormV2 ?? false,
      });
    },
    [commandId, formData, formType, isDirty, isSubmitting, locale, organization.id, sessionId, upsertCommand]
  );

  useDebouncedCallbackOnUpdate(props, onUpdateCallback, shouldCancel);
};

// Formats data and executes callback with a 2 seconds debounce. This is typically suited for frequent graphQL mutations.
const useDebouncedCallbackOnUpdate = <T extends object>(
  props: T,
  callback: (params: T) => void,
  shouldCancel: boolean
): void => {
  const onChange = useCallback(
    async (cbProps: T) => {
      const params: Record<string, unknown> = {};
      Object.entries(cbProps).map(([key, value]: [string, unknown]) => {
        if (typeof value !== "undefined") {
          // Convert empty string to null values.
          params[key] = value === "" ? null : value;
        }
      });

      if (!isTest) {
        callback(params as T);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(props)]
  );

  const { setDebouncedValue, cancel } = useDebounce<T>({
    onChange,
    // we want to debounce less in test mode because cypress can fill a form in less than 2 seconds
    debounced: 20_000,
  });

  useEffect(() => {
    setDebouncedValue(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDebouncedValue, JSON.stringify(props)]);

  useEffect(() => {
    if (shouldCancel) {
      cancel();
    }
  }, [cancel, shouldCancel]);
};

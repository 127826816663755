/* eslint-disable sonarjs/no-duplicate-string */

import { LiveEditorStepKey } from "../Steps/LiveEditorStep.enum";

import { usePreviewContext } from "./context/PreviewContext";

import { Placeholders } from "@/features/LiveFormEditor/types/LiveFormEditor.types";

export type GhostPreviewComponentName =
  | "BackgroundShapes"
  | "TicketingV2AddressSummary"
  | "FormV2Banner"
  | "TicketingSelectionV2"
  | "TicketingV2OccurrenceSelector"
  | "TicketingV2DateSummary"
  | "FormV2Description"
  | "TicketingV2AdditionalDonation"
  | "TicketingSelectionV2CardQuestions"
  | "TicketingV2DiscountCode"
  | "TicketingV2BuyerQuestionsSection"
  | "TicketingV2Step2SummarySection"
  | "FormV2PaymentSection"
  | "FormV2Thermometer"
  | "Amounts";

type GhostPreviewConfig = {
  isGhostIfBeforeStep?: LiveEditorStepKey;
  alternativeConditions?: ({
    placeholders,
    currentStepKey,
  }: {
    placeholders: Placeholders;
    currentStepKey: LiveEditorStepKey;
  }) => boolean;
};

const componentConfig: Record<GhostPreviewComponentName, GhostPreviewConfig> = {
  BackgroundShapes: { isGhostIfBeforeStep: "color" },
  TicketingV2AddressSummary: {
    isGhostIfBeforeStep: "location",
    alternativeConditions: ({ placeholders }) => placeholders.isLocation,
  },
  FormV2Banner: { isGhostIfBeforeStep: "banner" },
  TicketingSelectionV2: { isGhostIfBeforeStep: "tickets" },
  TicketingV2OccurrenceSelector: { isGhostIfBeforeStep: "date" },
  TicketingV2DateSummary: {
    isGhostIfBeforeStep: "date",
    alternativeConditions: ({ placeholders }) => placeholders.isDate,
  },
  FormV2Description: {
    isGhostIfBeforeStep: "description",
    alternativeConditions: ({ placeholders }) => placeholders.isDescription,
  },
  TicketingV2AdditionalDonation: {
    isGhostIfBeforeStep: "add-donation",
    alternativeConditions: ({ placeholders }) => placeholders.isExtraDonation,
  },
  TicketingSelectionV2CardQuestions: {
    isGhostIfBeforeStep: "guest-questions",
    alternativeConditions: ({ placeholders }) => placeholders.isGuestQuestions,
  },
  TicketingV2BuyerQuestionsSection: {
    isGhostIfBeforeStep: "buyer-questions",
    alternativeConditions: ({ placeholders }) => placeholders.isBuyerQuestions,
  },
  TicketingV2DiscountCode: {
    isGhostIfBeforeStep: "discount-codes",
    alternativeConditions: ({ placeholders }) => placeholders.isDiscountCode,
  },
  TicketingV2Step2SummarySection: {
    isGhostIfBeforeStep: "discount-codes",
    alternativeConditions: ({ currentStepKey }) => currentStepKey === "buyer-questions",
  },
  FormV2PaymentSection: {
    alternativeConditions: ({ currentStepKey }) => currentStepKey === "buyer-questions",
  },
  FormV2Thermometer: {
    isGhostIfBeforeStep: "fundraising-goal",
  },
  Amounts: {
    isGhostIfBeforeStep: "donation-options",
  },
};

export const useIsGhostPreview = (componentName: GhostPreviewComponentName): boolean => {
  const context = usePreviewContext();
  const { isPreview, mostAdvancedStepReachedIndex, currentStepKey, placeholders, liveEditorStepsKeys } = context;

  const config = componentConfig[componentName];
  if (!config || !isPreview) {
    return false;
  }

  const { isGhostIfBeforeStep, alternativeConditions } = config;

  // Component is a ghost if user hasn't reached its required step yet
  const isBeforeRequiredStep = isGhostIfBeforeStep
    ? mostAdvancedStepReachedIndex < liveEditorStepsKeys.indexOf(isGhostIfBeforeStep)
    : true;

  // Component is also a ghost if additional conditions are met
  const meetsAlternativeGhostConditions = alternativeConditions?.({ placeholders, currentStepKey }) ?? false;

  return isBeforeRequiredStep || meetsAlternativeGhostConditions;
};

import { Grid } from "@mui/material";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";

import { TextField } from "../../../../components/design-system/TextField";
import { getNestedErrorMessage } from "../../../../helpers/reactHookForm";
import { isRequired } from "../../../../helpers/validators";
import { useTranslate } from "../../../../hooks/useTranslate";

import { QuestionOutput } from "@/types/trpc";

export interface Props<T extends FieldValues> {
  form: UseFormReturn<T>;
  name: Path<T>;
  question: QuestionOutput;
  errorMessage?: string;
  isPreview: boolean;
}

export function QuestionFieldName<T extends FieldValues>({
  question,
  form,
  name: firstNameInputName,
  errorMessage: firstNameErrorMessage,
  isPreview,
}: Props<T>) {
  const { t } = useTranslate();
  const { control, formState } = form;

  const lastNameInputName = (firstNameInputName.slice(0, -1) + "1") as Path<T>; // Replace the last character 0 with 1 (e.g. "customAnswersOfParticipants.0.answers.0.answers.0" -> "customAnswersOfParticipants.0.answers.0.answers.1")
  const lastNameErrorMessage = getNestedErrorMessage(lastNameInputName, formState);

  const labelFirstName = t("dashboard", "donationForm.questionBodyDefaultValue.FirstName");
  const labelLastName = t("dashboard", "donationForm.questionBodyDefaultValue.LastName");

  return (
    <Grid container spacing={1} data-test="question-field-name">
      {/* First Name */}
      <Grid item xs>
        <TextField
          label={labelFirstName}
          control={control}
          name={firstNameInputName}
          rules={[isRequired(question.required, t("common", "answerRequired"))]}
          errorMessage={firstNameErrorMessage}
          data-test="question-field-first-name"
          disableInjectingGlobalStyles={isPreview} // We need this to make the LivePreview smooth
        />
      </Grid>

      {/* Last Name */}
      <Grid item xs>
        <TextField
          label={labelLastName}
          control={control}
          name={lastNameInputName}
          rules={[isRequired(question.required, t("common", "answerRequired"))]}
          errorMessage={lastNameErrorMessage}
          data-test="question-field-last-name"
          disableInjectingGlobalStyles={isPreview} // We need this to make the LivePreview smooth
        />
      </Grid>
    </Grid>
  );
}

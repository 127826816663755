import {
  ForwardedRef,
  ReactElement,
  forwardRef,
  useMemo,
  RefAttributes,
  useState,
  useCallback,
  FocusEvent,
  memo,
  type JSX,
} from "react";

import { styled, Theme } from "@mui/material/styles";
import { isEmailMisspelled } from "@simplyk/common";
import classnames from "classnames";
import { Control, FieldValues, useWatch } from "react-hook-form";

import { useTranslate } from "../../../hooks/useTranslate";
import { FormTextField } from "../TextField/FormTextField";
import { FormTextFieldProps } from "../TextField/types";

import { isEmail } from "@/helpers/validators";

const PREFIX = "EmailTextField";

const emailClasses = {
  helperTextWarning: `${PREFIX}-helperTextWarning`,
  helperTextError: `${PREFIX}-helperTextError`,
};

const StyledFormTextField = memo(
  styled(FormTextField)(({ theme }: { theme: Theme }) => ({
    [`& .${emailClasses.helperTextWarning}`]: {
      color: theme.palette.warning.main,
    },

    [`& .${emailClasses.helperTextError}`]: {
      color: theme.palette.error.main,
    },
  }))
);

export interface EmailTextFieldProps<T extends FieldValues> extends FormTextFieldProps<T> {
  checkMisspelledEmail?: boolean;
  variant?: "error" | "warning";
}

function EmailTextFieldInner<T extends FieldValues>(
  {
    checkMisspelledEmail = true,
    classes,
    control,
    errorMessage,
    helperText,
    name,
    onBlur,
    onFocus,
    rules,
    variant = "warning",
    ...rest
  }: EmailTextFieldProps<T>,
  ref: ForwardedRef<HTMLInputElement>
): ReactElement<FormTextFieldProps<T>> {
  const [showMisspellError, setShowMisspellError] = useState(false);
  const { t } = useTranslate();
  const watchedEmailValue = useWatch({ control, name });

  const emailMisspelledError = useMemo(() => {
    if (checkMisspelledEmail) {
      const misspelledResults = isEmailMisspelled(watchedEmailValue as string);
      if (misspelledResults && misspelledResults.length > 0) {
        return t("common", "misspelledEmail");
      }
    }
    return undefined;
  }, [checkMisspelledEmail, t, watchedEmailValue]);

  const currentRules = useMemo(
    () =>
      rules
        ? [...rules, isEmail(t("common", "emailFormat"), t("dashboard", "common.emailFormatWrongChar"))]
        : [isEmail(t("common", "emailFormat"), t("dashboard", "common.emailFormatWrongChar"))],
    [rules, t]
  );

  const textFieldClasses = useMemo(
    () => ({
      ...classes,
      inputLabel: {
        ...classes?.inputLabel,
        helperText: classnames(classes?.inputLabel?.helperText, {
          [emailClasses.helperTextError]: Boolean(emailMisspelledError) && variant === "error",
          [emailClasses.helperTextWarning]: Boolean(emailMisspelledError) && variant === "warning",
        }),
      },
    }),
    [classes, emailMisspelledError, variant]
  );

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onBlur?.(event);
      setShowMisspellError(true);
    },
    [onBlur]
  );

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onFocus?.(event);
      setShowMisspellError(Boolean(emailMisspelledError));
    },
    [emailMisspelledError, onFocus]
  );

  return (
    <StyledFormTextField
      {...rest}
      type="email"
      ref={ref}
      classes={textFieldClasses}
      // TODO jss-to-styled
      control={control as unknown as Control<FieldValues, unknown>}
      errorMessage={errorMessage}
      onBlur={handleBlur}
      onFocus={handleFocus}
      helperText={showMisspellError ? emailMisspelledError || helperText : helperText}
      // When variant=error, we want to show the error level on misspelled emails
      error={variant === "error" ? Boolean(emailMisspelledError || rest.error) : rest.error}
      name={name}
      rules={currentRules}
    />
  );
}

export const EmailTextField = forwardRef(EmailTextFieldInner) as unknown as <T extends FieldValues>(
  props: EmailTextFieldProps<T> & RefAttributes<HTMLInputElement>
) => JSX.Element;

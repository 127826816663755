import { useEffect, useRef, useState } from "react";

import { trpc } from "../../../helpers/trpc";
import { LiveEditorStep, LiveEditorStepKey } from "../Steps/LiveEditorStep.enum";

import { DonationFormOutput, TicketingOutput } from "@/types/trpc";

export const useTrackAndSaveMostAdvancedStepReached = ({
  currentStepIndex,
  apiFormObject,
  getIndexStepFromKey,
  currentStepKey,
  formType,
}: {
  currentStepKey: LiveEditorStepKey;
  currentStepIndex: LiveEditorStep;
  apiFormObject: TicketingOutput | DonationFormOutput;
  getIndexStepFromKey: (key: LiveEditorStepKey) => LiveEditorStep;
  getKeyStepFromIndex: (index: LiveEditorStep) => LiveEditorStepKey;
  formType: "ticketing" | "donation-form";
}) => {
  const saveTicketingStep = trpc.updateTicketingMostAdvancedStepReachedInLiveEditor.useMutation();
  const saveDonationFormStep = trpc.updateDonationFormMostAdvancedStepReachedInLiveEditor.useMutation();

  const previousStepIndexRef = useRef<LiveEditorStep | null>(null);

  const apiStepKey = (apiFormObject.liveFormEditorStep || "title") as LiveEditorStepKey;
  const apiStepIndex = apiStepKey ? getIndexStepFromKey(apiStepKey) : 1;

  const [mostAdvancedStepReached, setMostAdvancedStepReached] = useState<LiveEditorStep>(apiStepIndex);

  useEffect(() => {
    // Skip if it's the first render or same step
    if (previousStepIndexRef.current === currentStepIndex) {
      return;
    }

    // Only update if current step is greater than the most advanced step
    if (currentStepIndex > mostAdvancedStepReached) {
      setMostAdvancedStepReached(currentStepIndex);

      if (formType === "ticketing") {
        saveTicketingStep.mutate({
          ticketingId: apiFormObject.id,
          liveEditorStep: currentStepKey,
        });
      } else {
        saveDonationFormStep.mutate({
          donationFormId: apiFormObject.id,
          liveEditorStep: currentStepKey,
        });
      }
    }

    previousStepIndexRef.current = currentStepIndex;
  }, [
    currentStepIndex,
    apiFormObject.id,
    saveTicketingStep,
    apiFormObject.liveFormEditorStep,
    mostAdvancedStepReached,
    currentStepKey,
    formType,
    saveDonationFormStep,
  ]);

  return { mostAdvancedStepReached };
};

import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { FormType, getCurrencyFromCountry, OrganizationCountry, TicketingFormCategory } from "@simplyk/common";
import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form";
import { v4 } from "uuid";

import { useTicketingKeyValues } from "../../../components/TicketingDrawers/useTicketingKeyValues";
import { useFrontendAuctionContext } from "../../../contexts/FrontendAuctionContext";
import { FrontendTicketingContext } from "../../../contexts/FrontendTicketingContext";
import { getDisplayGenerateReceipt, getEligibleAmountTotal } from "../../../helpers/rates";
import { useTicketingFormUpdated } from "../../../hooks/useTicketingFormUpdated";
import { CustomAnswer } from "../../../types/customQuestion";
import { usePreviewContext } from "../../LiveFormEditor/LivePreview/context/PreviewContext";
import { getValidRatesSorted } from "../helpers/getValidRatesSorted";
import { useLocaleField } from "../helpers/useLocaleField";
import { FormV2StepKey } from "../types/FormV2StepKey";

import { FormV2Context } from "./FormV2Context";

import { OrganizationObject } from "@/gql-types";
import { TicketingPaymentInput } from "@/types/ticketing";
import { RateOutput, QuestionOutput, TicketingOutput } from "@/types/trpc";

export interface TicketingV2ProviderProps {
  type: FormType.Ticketing;
  formObject: TicketingOutput;
  currentStep: FormV2StepKey;
  setCurrentStep: (step: FormV2StepKey) => void;
  children: React.ReactNode;
}

export const TicketingV2Provider: React.FC<TicketingV2ProviderProps> = ({
  formObject: ticketing,
  currentStep,
  setCurrentStep,
  children,
}) => {
  const { questions, occurrencesWithRates, selectedOccurrenceId } = useContext(FrontendTicketingContext);
  const { commandBidsRateIdsMap, highestBidsMapByRateId } = useFrontendAuctionContext();
  const [isReady, setIsReady] = useState(false);
  const getLocaleField = useLocaleField();

  const rates = useMemo(() => ticketing.rates || [], [ticketing.rates]);
  const isAuction = ticketing.formCategory === TicketingFormCategory.Auction;

  const [validationMode, setValidationMode] = useState<"onBlur" | "onChange">("onBlur");

  const auctionCustomAnswersOfParticipants = useMemo(() => {
    return rates.map((rate) => ({
      rateId: rate.id,
      answers: questions.filter((question) => question.hasRate === false).map(initAnswer),
    }));
  }, [rates, questions]);

  const form = useForm<TicketingPaymentInput>({
    defaultValues: {
      ticketsPurchased: initTicketsPurchased(rates),
      customAnswersOfParticipants: isAuction ? auctionCustomAnswersOfParticipants : [],
      customAnswersOfOrder: [],
    },
    mode: validationMode,
    shouldFocusError: true /* Custom error scroll on step 1, but leave this true for step 2 */,
  });
  const { control, reset, setValue } = form;

  useEffect(() => {
    if (selectedOccurrenceId) {
      form.setValue("occurrenceId", selectedOccurrenceId);
    }
  }, [form, selectedOccurrenceId]);

  const { isPreview } = usePreviewContext();

  if (!isPreview) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTicketingFormUpdated({ control });
  }

  useTicketingKeyValues({ control, setValue }); // We need this to set the automatic renewal value

  const customAnswersOfParticipantsFieldArray = useFieldArray({
    control,
    name: "customAnswersOfParticipants",
  });
  const extraDonation = useWatch({
    control,
    name: "extraDonation",
  });
  const ticketsPurchased = useWatch({
    control,
    name: "ticketsPurchased",
  });

  const { validDiscount } = useContext(FrontendTicketingContext);

  const resetForm = useCallback(() => {
    reset({
      extraDonation,
      ticketsPurchased: initTicketsPurchased(rates),
      customAnswersOfParticipants: [],
      customAnswersOfOrder: initCustomAnswersOfOrder(questions),
      occurrenceId: selectedOccurrenceId || undefined,
    });
  }, [extraDonation, questions, rates, reset, selectedOccurrenceId]);

  // Update the form state when questions are loaded
  useEffect(() => {
    if (questions && questions.length > 0 && !isReady) {
      resetForm();
      setIsReady(true);
    }
  }, [questions, reset, rates, isReady, resetForm]);

  const validRatesSorted = useMemo(() => {
    return getValidRatesSorted({
      rates,
      isAuction,
      commandBidsRateIdsMap,
      highestBidsMapByRateId,
    });
  }, [rates, isAuction, commandBidsRateIdsMap, highestBidsMapByRateId]);

  const hasLocation = Boolean(ticketing.address);

  const hasDate = Boolean(occurrencesWithRates.length >= 1 && occurrencesWithRates[0].occurrence);
  const hasBanner = Boolean(ticketing.bannerUrl || ticketing.bannerVideoUrl);
  const isMembership =
    ticketing.formCategory === TicketingFormCategory.MembershipV2 ||
    ticketing.formCategory === TicketingFormCategory.Membership;

  const formFields = ticketing.ticketingFields || [];
  const localeField = getLocaleField(formFields)!;
  const description = localeField?.sanitizedDescription ?? undefined;

  const formOrganization = ticketing.organization! as OrganizationObject;

  const eligibleAmountTotal = getEligibleAmountTotal(ticketsPurchased, ticketing, extraDonation ?? 0, validDiscount);
  const displayGenerateReceipt =
    getDisplayGenerateReceipt(ticketsPurchased, ticketing, extraDonation ?? 0) && formOrganization.canGenerateReceipt;

  return (
    <FormProvider {...form}>
      <FormV2Context.Provider
        value={{
          type: FormType.Ticketing,
          formObject: ticketing,
          form,
          customAnswersOfParticipantsFieldArray,
          currentStep,
          setCurrentStep,
          validRatesSorted,
          resetForm,
          hasDate,
          hasLocation,
          setValidationMode,
          hasBanner,
          isMembership,
          isAuction,
          formFields,
          localeField,
          description,
          formCategory: ticketing.formCategory,
          questions,
          ticketsPurchased,
          formOrganization,
          formCountry: ticketing.organization?.country || OrganizationCountry.UnitedStates,
          formCurrency: getCurrencyFromCountry(formOrganization?.country || OrganizationCountry.UnitedStates),
          displayGenerateReceipt,
          eligibleAmountTotal,
        }}
      >
        {children}
      </FormV2Context.Provider>
    </FormProvider>
  );
};

// Helper functions
const initTicketsPurchased = (rates: RateOutput[]) => {
  return rates.map((rate) => ({
    rateId: rate.id,
    purchasedNumber: 0,
    attendeesByTicket: rate.attendeesByTicket || 1,
  }));
};

const initCustomAnswersOfOrder = (questions: QuestionOutput[]) => {
  return questions.filter((question) => question.hasRate === false).map(initAnswer);
};

export const initAnswer = (question: QuestionOutput): CustomAnswer => {
  return {
    questionId: question.id,
    id: v4(),
    answers: [],
    choiceIds: [],
  };
};

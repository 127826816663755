import * as React from "react";

import Grid, { GridProps } from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import classNames from "classnames";

const PREFIX = "DisplayGrid";

const classes = {
  hidden: `${PREFIX}-hidden`,
  visible: `${PREFIX}-visible`,
};

const StyledGrid = React.memo(
  styled(Grid)({
    [`&&.${classes.hidden}`]: {
      display: "none",
      position: "absolute",
      height: 0,
    },
    [`&&.${classes.visible}`]: {
      maxWidth: "100%",
      position: "relative",
      height: "auto",
    },
  })
);

interface DisplayGridProps extends GridProps {
  when: boolean;
}

export const DisplayGrid: React.FunctionComponent<React.PropsWithChildren<DisplayGridProps>> = ({
  when,
  children,
  className: propsClassName,
  ...props
}) => {
  return (
    <StyledGrid {...props} className={classNames(propsClassName, { [classes.hidden]: !when, [classes.visible]: when })}>
      {children}
    </StyledGrid>
  );
};

import { FieldErrors, FieldValues, FormState, Path } from "react-hook-form";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrorMessages<T extends Record<string, any>>({
  keys,
  errors,
}: {
  keys: Array<keyof T>;
  errors: FieldErrors<T>;
}) {
  return keys.reduce<string[]>((acc, key) => {
    const message = errors[key]?.message as string | undefined;
    if (message) {
      return [...acc, message];
    }
    return acc;
  }, []);
}

export const getNestedErrorMessage = <T extends FieldValues>(
  name: Path<T>,
  formState: FormState<T>
): string | undefined => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error = name.split(".").reduce((acc, key) => (acc as any)?.[key], formState.errors) as { message?: string };

  return error?.message;
};

import { Locales, getField } from "@simplyk/common";

import { useLocaleContext } from "../../../contexts/LocaleContext";

export const useLocaleField = () => {
  const { locale } = useLocaleContext();

  return <T extends { locale: Locales }>(fields: T[] | undefined | null): T | undefined => {
    return getField(fields, locale);
  };
};

import { useContext, useEffect, useMemo } from "react";

import { MembershipValidityPeriod, StripeRecurringInterval, getMembershipExpirationDate } from "@simplyk/common";
import { Control, Path, UseFormSetValue, useWatch } from "react-hook-form";

import { useFrontendAuctionContext } from "../../contexts/FrontendAuctionContext";
import { FrontendFormContext } from "../../contexts/FrontendFormContext";
import { FrontendTicketingContext } from "../../contexts/FrontendTicketingContext";
import { getOrderAmountTotal, getUniquelySelectedTicketIndex, getUniquelySelectedRate } from "../../helpers/rates";

import { computeTicketsPrice } from "@/helpers/ticket";
import { TicketingPaymentInput } from "@/types/ticketing";

export const useTicketingKeyValues = ({
  control,
  setValue,
}: {
  control: Control<TicketingPaymentInput>;
  setValue?: UseFormSetValue<TicketingPaymentInput>;
}) => {
  const { ticketing, validDiscount, isAuction, setMembershipRenewalBuyerInfo } = useContext(FrontendTicketingContext);
  const { commandBidsRateIdsMap, highestBidsMapByRateId } = useFrontendAuctionContext();
  const {
    isMembershipV2,
    setStripeRecurrenceInterval,
    displayedFormAmount,
    setDisplayedFormAmount,
    displayedFormAmountWithTip,
  } = useContext(FrontendFormContext);
  const ticketsPurchased = useWatch({ control, name: "ticketsPurchased" });
  const uniquelySelectedTicketIndex = getUniquelySelectedTicketIndex(ticketsPurchased, ticketing.rates);
  const uniquelySelectedRate = getUniquelySelectedRate(ticketsPurchased, ticketing.rates || []);

  const automaticRenewalFormName =
    `ticketsPurchased.${uniquelySelectedTicketIndex || 0}.automaticRenewal` as Path<TicketingPaymentInput>;

  const automaticRenewal = useWatch({ control, name: automaticRenewalFormName });

  // Set the automatic renewal default value:
  // if rate amount is 0 or if uniquelySelectedRate membership is undefined, set it to false
  // if recurring type is yearly or adDate and rate allow automatic renewal, set it to true
  // if recurring type is monthly, set it to true
  // if rate amount is 0, set it to false
  useEffect(() => {
    if (!uniquelySelectedRate || !uniquelySelectedRate?.membershipValidityPeriod || displayedFormAmount === 0) {
      setValue?.(automaticRenewalFormName, false);
    } else if (
      ([MembershipValidityPeriod.OneYearAfterPayment, MembershipValidityPeriod.AtDate].includes(
        uniquelySelectedRate.membershipValidityPeriod
      ) &&
        uniquelySelectedRate.allowAutomaticRenewal) ||
      uniquelySelectedRate?.membershipValidityPeriod === MembershipValidityPeriod.OneMonthAfterPayment
    ) {
      setValue?.(automaticRenewalFormName, true);
    } else {
      setValue?.(automaticRenewalFormName, false);
    }
  }, [
    uniquelySelectedTicketIndex,
    uniquelySelectedRate?.membershipValidityPeriod,
    setValue,
    uniquelySelectedRate?.allowAutomaticRenewal,
    uniquelySelectedRate?.amount,
    uniquelySelectedRate,
    automaticRenewalFormName,
    displayedFormAmount,
  ]);

  const expirationDate = useMemo(() => {
    const date =
      uniquelySelectedRate &&
      uniquelySelectedRate.membershipValidityPeriod &&
      getMembershipExpirationDate({
        membershipValidityPeriod: uniquelySelectedRate.membershipValidityPeriod,
        expirationDate: uniquelySelectedRate.membershipValidityPeriodAtDate
          ? new Date(uniquelySelectedRate.membershipValidityPeriodAtDate)
          : undefined,
        paymentDate: new Date(), // The payment date isn't editable, so use the current date here
      });
    if (!date) {
      return undefined;
    }
    return date;
  }, [uniquelySelectedRate]);

  // AutomaticRenewal choice is proposed only for yearly type recurrence if allowed by the rate
  const shouldProposeAutomaticRenewal = Boolean(
    isMembershipV2 &&
      Boolean(uniquelySelectedRate?.allowAutomaticRenewal) &&
      uniquelySelectedTicketIndex !== -1 &&
      uniquelySelectedRate &&
      uniquelySelectedRate.membershipValidityPeriod &&
      [MembershipValidityPeriod.OneYearAfterPayment, MembershipValidityPeriod.AtDate].includes(
        uniquelySelectedRate.membershipValidityPeriod
      ) &&
      uniquelySelectedRate.amount > 0 &&
      displayedFormAmountWithTip > 0
  );

  // We set the membership info in the context
  useEffect(() => {
    setMembershipRenewalBuyerInfo({
      expirationDate,
      uniquelySelectedTicketIndex,
      shouldProposeAutomaticRenewal,
      validityPeriod: uniquelySelectedRate?.membershipValidityPeriod ?? null,
    });
  }, [
    expirationDate,
    setMembershipRenewalBuyerInfo,
    shouldProposeAutomaticRenewal,
    uniquelySelectedRate?.membershipValidityPeriod,
    uniquelySelectedTicketIndex,
  ]);

  const ticketsPrice = ticketing.rates ? computeTicketsPrice(ticketing.rates, ticketsPurchased) : 0;
  const extraDonation = useWatch({
    control,
    name: "extraDonation",
  });

  const amount = getOrderAmountTotal({
    ticketsPurchased,
    rates: ticketing.rates || [],
    extraDonation: extraDonation || 0,
    isAuction,
    discount: validDiscount,
    commandBidsRateIdsMap,
    highestBidsMapByRateId,
  });

  const hasNoETickets =
    !ticketsPurchased ||
    Boolean(
      ticketsPurchased.every(
        (ticket) => !ticket.purchasedNumber || Boolean(ticket?.purchasedNumber && ticket.purchasedNumber === 0)
      )
    );

  // Set the stripe recurrence interval:
  // to be not null, displayed amount need to be greater than 0, for a membership with a rate with automatic renewal
  useEffect(() => {
    const ticketingRecurrence =
      isMembershipV2 && automaticRenewal && uniquelySelectedRate?.membershipValidityPeriod
        ? [MembershipValidityPeriod.OneYearAfterPayment, MembershipValidityPeriod.AtDate].includes(
            uniquelySelectedRate?.membershipValidityPeriod
          )
          ? StripeRecurringInterval.year
          : uniquelySelectedRate?.membershipValidityPeriod === MembershipValidityPeriod.OneMonthAfterPayment
            ? StripeRecurringInterval.month
            : null
        : null;
    setStripeRecurrenceInterval(ticketingRecurrence);
  }, [
    automaticRenewal,
    displayedFormAmount,
    isMembershipV2,
    setStripeRecurrenceInterval,
    uniquelySelectedRate?.membershipValidityPeriod,
  ]);

  useEffect(() => {
    setDisplayedFormAmount(amount);
  }, [amount, setDisplayedFormAmount]);

  return { automaticRenewal, ticketsPrice, amount, hasNoETickets };
};

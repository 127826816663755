/* eslint-disable @typescript-eslint/naming-convention */
export type LiveEditorStep = number;

export type LiveEditorStepKey =
  | "title"
  | "date"
  | "location"
  | "fundraising-goal"
  | "description"
  | "color"
  | "banner"
  | "logo"
  | "tickets"
  | "donation-options"
  | "guest-questions"
  | "add-donation"
  | "tax-receipts"
  | "buyer-questions"
  | "discount-codes"
  | "business-model"
  | "e-ticket"
  | "thank-you-email"
  | "advanced-settings"
  | "review";

// Do not use this to get the order
const nonOrderedLiveEditorStepsKeys: LiveEditorStepKey[] = [
  "title",
  "date",
  "location",
  "description",
  "color",
  "banner",
  "logo",
  "tickets",
  "guest-questions",
  "add-donation",
  "buyer-questions",
  "discount-codes",
  "thank-you-email",
  "advanced-settings",
  "e-ticket",
  "review",
  "donation-options",
  "fundraising-goal",
  "tax-receipts",
  "business-model",
];

export const isValidStepKey = (key: unknown): key is LiveEditorStepKey =>
  typeof key === "string" && nonOrderedLiveEditorStepsKeys.includes(key as LiveEditorStepKey);

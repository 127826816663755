import React, { createContext, PropsWithChildren, useContext } from "react";

import { LiveEditorStep, LiveEditorStepKey } from "../../Steps/LiveEditorStep.enum";

import { Placeholders } from "@/features/LiveFormEditor/types/LiveFormEditor.types";

interface PreviewContextType {
  isPreview: boolean;
  mostAdvancedStepReachedIndex: LiveEditorStep;
  mostAdvancedStepReachedKey: LiveEditorStepKey;
  currentStepKey: LiveEditorStepKey;
  placeholders: Placeholders;
  liveEditorStepsKeys: LiveEditorStepKey[];
}

export const defaultPlaceholders: Placeholders = {
  isDate: false,
  isLocation: false,
  isLogo: false,
  isDescription: false,
  isExtraDonation: false,
  isGuestQuestions: false,
  isDiscountCode: false,
  isBuyerQuestions: false,
};

export const PreviewContext = createContext<PreviewContextType>({
  isPreview: false,
  mostAdvancedStepReachedIndex: 0,
  mostAdvancedStepReachedKey: "title",
  currentStepKey: "title",
  liveEditorStepsKeys: [],
  placeholders: {
    isDate: false,
    isLocation: false,
    isLogo: false,
    isDescription: false,
    isExtraDonation: false,
    isGuestQuestions: false,
    isDiscountCode: false,
    isBuyerQuestions: false,
  },
});

export const PreviewProvider: React.FC<PropsWithChildren<PreviewContextType>> = ({
  children,
  isPreview,
  mostAdvancedStepReachedIndex,
  mostAdvancedStepReachedKey,
  placeholders,
  currentStepKey,
  liveEditorStepsKeys,
}) => {
  return (
    <PreviewContext.Provider
      value={{
        isPreview,
        mostAdvancedStepReachedIndex,
        mostAdvancedStepReachedKey,
        currentStepKey,
        placeholders,
        liveEditorStepsKeys,
      }}
    >
      {children}
    </PreviewContext.Provider>
  );
};

export const usePreviewContext = () => {
  return useContext(PreviewContext);
};

import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { TicketingFormCategory, Utils } from "@simplyk/common";
import { useRouter } from "next/router";

import { AmplitudeEvents } from "../constants/amplitude";
import { trpc } from "../helpers/trpc";
import { useAmplitude } from "../hooks/amplitude/useAmplitude";
import { PublicRoutes } from "../routes/routes";

import { FrontendFormContext } from "./FrontendFormContext";

import { TicketingOutput } from "@/types/trpc";

type HighestProductBidsPerRateObject = {
  rateId: string;
  amount: number;
};

export interface FrontendAuctionContextValues {
  highestBidsMapByRateId:
    | {
        [k: string]: HighestProductBidsPerRateObject;
      }
    | undefined;
  commandBidsRateIdsMap:
    | Record<
        string,
        {
          rateId: string;
          amount: number;
        }
      >
    | null
    | undefined;
  hasAlreadyBid: boolean;
  existingCommand: { email: string | null; firstName: string | null } | undefined;
  refreshAuctionData: () => Promise<void>;
  areHighestBidsLoading: boolean;
  isLoading: boolean;
}

const defaultValues: FrontendAuctionContextValues = {
  highestBidsMapByRateId: {},
  commandBidsRateIdsMap: {},
  hasAlreadyBid: false,
  existingCommand: undefined,
  refreshAuctionData: async () => {},
  areHighestBidsLoading: false,
  isLoading: true,
};

interface TicketingProviderProps {
  ticketing: TicketingOutput;
}

export const FrontendAuctionContext = createContext<FrontendAuctionContextValues>(defaultValues);

export const useFrontendAuctionContext = () => useContext(FrontendAuctionContext);

const FrontendAuctionInnerProvider: FunctionComponent<PropsWithChildren<TicketingProviderProps>> = ({
  children,
  ticketing,
}) => {
  const router = useRouter();
  const { logAmplitudeEvent } = useAmplitude();
  const { setSelectedPaymentMethod } = useContext(FrontendFormContext);

  const [getHighestBidsPerRatePollInterval, setGetHighestBidsPerRatePollInterval] = useState<number>(5000);

  const isGetHighestBidsPerRateEnabled = Boolean(ticketing.formCategory === TicketingFormCategory.Auction);
  const {
    data: highestBidsPerRate,
    isLoading: areHighestBidsLoading,
    // Use refetchHighestBidsPerRateCallback instead
    refetch: refetchHighestBidsPerRate,
  } = trpc.form_getHighestBidsPerRate.useQuery(
    { ticketingId: ticketing.id },
    {
      enabled: isGetHighestBidsPerRateEnabled,
      refetchInterval: getHighestBidsPerRatePollInterval,
    }
  );

  const refetchHighestBidsPerRateCallback = useCallback(() => {
    if (isGetHighestBidsPerRateEnabled) {
      refetchHighestBidsPerRate();
    }
  }, [refetchHighestBidsPerRate, isGetHighestBidsPerRateEnabled]);

  const highestBidsMapByRateId = useMemo(() => {
    return highestBidsPerRate && Utils.arrayToKeyMap(highestBidsPerRate, (bid) => bid.rateId);
  }, [highestBidsPerRate]);

  const isGetCommandBidsEnabled = Boolean(ticketing.formCategory === TicketingFormCategory.Auction);

  const bidderToken = router.query?.bidderToken as string | undefined;
  // eslint-disable-next-line no-console
  console.log("bidderToken", bidderToken, router.query);
  const {
    data: commandBidsData,
    error,
    refetch: refetchCommandBids,
  } = trpc.form_getCommandBids.useQuery(
    { bidderToken, ticketingId: ticketing.id },
    { enabled: isGetCommandBidsEnabled }
  );
  const refetchCommandBidsCallback = useCallback(() => {
    if (isGetCommandBidsEnabled) {
      refetchCommandBids();
    }
  }, [refetchCommandBids, isGetCommandBidsEnabled]);

  useEffect(() => {
    if (bidderToken) {
      logAmplitudeEvent(AmplitudeEvents.TrackBidsClicked, { from: "ConfirmationEmail" });
    }
  }, [logAmplitudeEvent, bidderToken]);

  useEffect(() => {
    if (error?.shape?.data?.code === "bidder_token_expired" || error?.shape?.data?.code === "invalid_bidder_token") {
      router.push({ pathname: PublicRoutes.ExpiredBidderLink, query: { backUrl: router.asPath } });
    }
  }, [error?.shape?.data?.code, router]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        setGetHighestBidsPerRatePollInterval(0);
      } else if (document.visibilityState === "visible") {
        setGetHighestBidsPerRatePollInterval(5000);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const refreshAuctionData = useCallback(async () => {
    await refetchHighestBidsPerRateCallback();
    await refetchCommandBidsCallback();
  }, [refetchCommandBidsCallback, refetchHighestBidsPerRateCallback]);

  const commandBidsRateIdsMap =
    commandBidsData && Utils.arrayToKeyMap(commandBidsData.productBids, (item) => item.rateId);
  const hasAlreadyBid = Boolean(commandBidsData && commandBidsData.productBids.length > 0);
  const bidPaymentMethod = commandBidsData?.command.paymentMethod;

  useEffect(() => {
    if (bidPaymentMethod) {
      setSelectedPaymentMethod(bidPaymentMethod, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidPaymentMethod]);

  const existingCommand = commandBidsData?.command;
  const isLoading = !commandBidsData;

  return (
    <FrontendAuctionContext.Provider
      value={{
        highestBidsMapByRateId,
        commandBidsRateIdsMap,
        hasAlreadyBid,
        existingCommand,
        refreshAuctionData,
        areHighestBidsLoading,
        isLoading,
      }}
    >
      {children}
    </FrontendAuctionContext.Provider>
  );
};

export const FrontendAuctionProvider: FunctionComponent<PropsWithChildren<TicketingProviderProps>> = (props) => {
  if (props.ticketing.formCategory !== TicketingFormCategory.Auction) {
    return <>{props.children}</>;
  }

  return <FrontendAuctionInnerProvider {...props} />;
};

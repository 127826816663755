import { FC, useEffect, useState } from "react";

import { useTheme } from "@mui/material";
import { Emptyable } from "@simplyk/common";

import { sanitizeHtmlString } from "../../helpers/html";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useTranslate } from "../../hooks/useTranslate";
import { Button, ButtonProps } from "../design-system/Button";
import { Typography, TypographyProps } from "../design-system/Typography";

import { ChevronDown, ChevronUp } from "@/icons/outlined";

export interface ExpandableTextProps {
  text: Emptyable<string>;
  isInitiallyExpanded?: boolean;
  buttonProps?: (isExpanded: boolean) => ButtonProps;
  textProps?: (isExpanded: boolean) => Omit<TypographyProps, "ref">;
}

export const ExpandableText: FC<ExpandableTextProps> = ({
  text,
  isInitiallyExpanded: initiallyExpanded = false,
  buttonProps = () => ({}),
  textProps = () => ({}),
}) => {
  const theme = useTheme();
  const { t } = useTranslate();
  const { isSmallScreen } = useMediaQuery();

  const [isExpanded, setIsExpanded] = useState(initiallyExpanded);

  const trimmedText = sanitizeHtmlString(text);

  useEffect(() => {
    setIsExpanded(initiallyExpanded);
  }, [initiallyExpanded]);

  if (!trimmedText) {
    return null;
  }

  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <>
      <Button
        onClick={handleToggle}
        sx={{
          color: `${theme.palette.text.form.moderate} !important`,
          borderColor: `${theme.palette.border.form.moderate} !important`,
          mb: 1,
        }}
        variant="outlined"
        endIcon={isExpanded ? <ChevronUp /> : <ChevronDown />}
        data-test="expandable-text-expand-button"
        {...buttonProps(isExpanded)}
      >
        {isExpanded ? t("ticketing", "lessDetails") : t("ticketing", "moreDetails")}
      </Button>

      {isExpanded && (
        <Typography
          variant={isSmallScreen ? "body2" : "body1"}
          vibe="text-form-moderate"
          component="div"
          textOverflow="clip"
          data-test="expandable-text-description-expanded"
          sx={{
            maxWidth: "100%", // ensure the parent does not overflow
            overflow: "hidden", // prevent content from spilling out
            boxSizing: "border-box",
            "& p": {
              // Override the width that came from DB
              width: "auto !important",
              // Keep the margin from the DB
              // or adjust if needed: marginLeft: "40px !important"
              boxSizing: "border-box",
              // Override the color that came from DB for live editor preview
              color: "inherit !important",
            },
          }}
          dangerouslySetInnerHTML={{ __html: trimmedText }}
          {...textProps(isExpanded)}
        />
      )}
    </>
  );
};

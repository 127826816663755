import emailMisspelled, { all } from "email-misspelled";
const isDomainInall = (domain) => {
    return all.some((allDomain) => {
        return domain.includes(allDomain);
    });
};
export const getWebsiteFromEmail = (email) => {
    const domainName = email.split("@").pop();
    if (domainName && isDomainInall(domainName)) {
        return null;
    }
    return domainName || null;
};
export const isEmailMisspelled = (email) => {
    const emailChecker = emailMisspelled({ domains: all });
    return emailChecker(email);
};

import { Locales, getField } from "@simplyk/common";

import { ChoiceFieldOutput, QuestionOutput } from "@/types/trpc";

export const getChoiceLabel = (objects: Array<ChoiceFieldOutput>, locale: Locales): string => {
  return getField(objects, locale)?.body || "";
};

export const getQuestionLabel = (question: QuestionOutput, locale: Locales): string => {
  const questionField = question?.questionFields?.find((field) => field.locale === locale);
  return questionField?.body || "";
};

import { getCentsValue, getDollarValue } from "@simplyk/common";

export const parseDefaultDisplayValue = (value: number | undefined) => {
  if (value === 0) {
    return "0";
  }
  if (value) {
    return getDollarValue(value).toString();
  }
  return "";
};

export const parseInputValue = (value: string) => {
  // Check if value is too large to be safely handled
  const MAX_SAFE_AMOUNT = 10000000000; // $999,999,999,999.99
  const numericValue = parseFloat(value);

  if (numericValue > MAX_SAFE_AMOUNT) {
    return MAX_SAFE_AMOUNT;
  }

  const parsedValue = getCentsValue(numericValue);
  return isNaN(parsedValue) || parsedValue === null ? null : Math.round(parsedValue);
};

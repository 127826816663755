import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(
  {
    tooltipContainer: {
      position: "relative",
      display: "inline-flex",
    },
  },
  { index: 1 }
);

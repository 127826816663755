import { useMemo } from "react";

import { MenuProps, useTheme } from "@mui/material";
import { FieldValues, Path, UseFormReturn, useWatch } from "react-hook-form";

import { getChoiceDisabled } from "../../../../components/CustomAnswer/choices.helper";
import { Divider } from "../../../../components/design-system/Divider";
import { MenuItem } from "../../../../components/design-system/MenuItem";
import { Select } from "../../../../components/design-system/Select";
import { useTranslate } from "../../../../hooks/useTranslate";

import { QuestionFieldChoice } from "./QuestionFieldChoice";
import { useAnswersOfParticipantsSelectedChoicesCount } from "./useAnswersOfParticipantsSelectedChoicesCount";

import { ChoiceOutput, QuestionOutput } from "@/types/trpc";

export interface QuestionFieldSelectProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  name: Path<T>;
  question: QuestionOutput;
  label?: string;
  errorMessage?: string;
  isPreview: boolean;
}

const ITEM_HEIGHT = 48;
const menuProps: Partial<MenuProps> = {
  PaperProps: {
    style: {
      marginTop: "8px",
      maxHeight: ITEM_HEIGHT * 5, // We we always show 5 lines max with scrollbar visible
      overflowY: "auto",
      width: 300,
    },
    "data-test": "question-field-select-menu",
  },
  MenuListProps: {
    dense: true,
  },
};

export function QuestionFieldSelect<T extends FieldValues>({
  question,
  form,
  name,
  label,
  errorMessage,
  isPreview,
}: QuestionFieldSelectProps<T>) {
  const { t } = useTranslate();
  const theme = useTheme();
  const { control } = form;

  const sortedQuestionChoices: ChoiceOutput[] | undefined = useMemo(
    () => question.choices?.slice().sort((choiceA, choiceB) => choiceA.sortIndex - choiceB.sortIndex) || undefined,
    [question.choices]
  );

  const hasMaximum = Boolean(sortedQuestionChoices?.[0]?.maximum);

  const answersOfParticipantsSelectedChoicesCount = useAnswersOfParticipantsSelectedChoicesCount(form);

  const selectedChoiceId = useWatch({ control, name });

  return (
    <Select
      key={question.id}
      name={name}
      label={label}
      control={control}
      required={question?.required}
      rules={[{ required: { value: question?.required, message: t("common", "answerRequired") } }]}
      errorMessage={errorMessage}
      defaultValue={hasMaximum ? undefined : sortedQuestionChoices?.[0]?.id}
      disableAutocomplete={false}
      disableAutoFocus={false}
      data-test="question-field-select"
      MenuProps={menuProps}
      disableInjectingGlobalStyles={isPreview} // We need this to make the LivePreview smooth
      sx={{ minHeight: 40, boxSizing: "border-box" }}
    >
      {sortedQuestionChoices?.flatMap((choice, index) => [
        <MenuItem
          key={`choice-${choice.id}`}
          value={choice.id}
          data-test="question-field-select-item"
          disabled={getChoiceDisabled(choice, answersOfParticipantsSelectedChoicesCount)}
          sx={{ width: "100%" }}
        >
          <QuestionFieldChoice
            key={`choice-${choice.id}`}
            choice={choice}
            answersOfParticipantsSelectedChoicesCount={answersOfParticipantsSelectedChoicesCount}
            hideAvailability={choice.id === selectedChoiceId}
          />
        </MenuItem>,

        index < sortedQuestionChoices.length - 1 ? (
          <Divider
            key={`divider-${choice.id}`}
            sx={{ marginY: "0 !important" }}
            vibe={theme.palette.border.form.moderate}
          />
        ) : null,
      ])}
    </Select>
  );
}

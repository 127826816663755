import { ChoiceOutput } from "@/types/trpc";

export const getChoiceAvailableCount = (
  choice: ChoiceOutput,
  answersOfParticipantsSelectedChoicesCount: Record<string, number> | undefined
) => {
  return (
    (choice.maximum &&
      choice.maximum -
        (choice.countAlreadyAnswered || 0) -
        (answersOfParticipantsSelectedChoicesCount?.[choice.id] || 0)) ||
    0
  );
};

export const getChoiceDisabled = (
  choice: ChoiceOutput,
  answersOfParticipantsSelectedChoicesCount: Record<string, number> | undefined
) => {
  return Boolean(
    choice.maximum !== null &&
      choice.maximum !== undefined &&
      getChoiceAvailableCount(choice, answersOfParticipantsSelectedChoicesCount) <= 0
  );
};

/* eslint-disable @typescript-eslint/naming-convention */
import React, { createContext, useCallback, useContext, useRef, useState } from "react";

import { useLiveFormEditorContext } from "../../common/LiveFormEditorContext";
import { LiveEditorStepKey } from "../../Steps/LiveEditorStep.enum";

export const TICKETING_SELECTION_V2_INNER_ID = "ticketing-selection-v2-inner";
export const TICKETING_SELECTION_V2_RATE_ID_PREFIX = "ticketing-selection-v2-rate-";

interface ElementIdArgs {
  rateIndexInFocus?: number;
  questionIndexInFocus?: number;
}

type ElementIdResolver = string | ((args: ElementIdArgs) => string | undefined) | undefined;

export const STEP_TO_ELEMENT_ID = {
  title: "form-title",
  date: "ticketing-v2-date-summary",
  location: "ticketing-v2-address-summary",
  description: "form-v2-description",
  color: undefined,
  banner: "ticketing-v2-banner",
  logo: "ticketing-v2-logo",
  tickets: ({ rateIndexInFocus }) => {
    return `${TICKETING_SELECTION_V2_RATE_ID_PREFIX}${rateIndexInFocus ?? 0}`;
  },
  "add-donation": "extra-donation-amount-input",
  "thank-you-email": "live-preview-email",
  "guest-questions": ({ questionIndexInFocus }) => {
    return `rate-0-group-0-participant-0-question-${questionIndexInFocus ?? 0}`;
  },
  "discount-codes": "discount-code-field",
  "business-model": "tip-section-input",
  "buyer-questions": ({ questionIndexInFocus }) => {
    return questionIndexInFocus !== undefined
      ? `buyer-question-${questionIndexInFocus}`
      : `${TICKETING_SELECTION_V2_RATE_ID_PREFIX}0`;
  },
  "e-ticket": undefined,
  "advanced-settings": undefined,
  review: undefined,
  "donation-options": undefined,
  "tax-receipts": undefined,
  "fundraising-goal": undefined,
} as const satisfies Record<LiveEditorStepKey, ElementIdResolver>;

interface ArrowContextType {
  arrowTop: number;
  refreshArrowPosition: (args?: { rateIndexInFocus?: number; questionIndexInFocus?: number }) => void;
  isArrowVisible: boolean;
  showArrow: () => void;
  hideArrow: (speed?: "slow" | "fast") => void;
  transitionSpeed: "slow" | "fast";
  userHasScrolled: React.RefObject<boolean>;
}

const ArrowContext = createContext<ArrowContextType>({
  arrowTop: 0,
  refreshArrowPosition: () => {},
  isArrowVisible: false,
  showArrow: () => {},
  hideArrow: () => {},
  transitionSpeed: "slow",
  userHasScrolled: { current: false },
});

interface ArrowProviderProps {
  children: React.ReactNode;
}

export const ArrowProvider: React.FC<ArrowProviderProps> = ({ children }) => {
  const { currentStepKey, iframeRef } = useLiveFormEditorContext();
  const [arrowTop, setArrowTop] = useState(0);
  const [isArrowVisible, setIsArrowVisible] = useState(true);
  const [transitionSpeed, setTransitionSpeed] = useState<"slow" | "fast">("slow");
  const userHasScrolled = useRef(false);

  const showArrow = useCallback(() => {
    setIsArrowVisible(true);
    setTransitionSpeed("slow");
  }, []);

  const hideArrow = useCallback((speed: "slow" | "fast" = "fast") => {
    setTransitionSpeed(speed);
    setIsArrowVisible(false);
  }, []);

  const refreshArrowPosition = useCallback(
    (context?: { rateIndexInFocus?: number; questionIndexInFocus?: number }) => {
      const iframe = iframeRef.current;
      if (!iframe?.contentDocument) {
        return;
      }

      const elementIdResolver = STEP_TO_ELEMENT_ID[currentStepKey];

      if (!elementIdResolver) {
        setArrowTop(0);
        return;
      }

      const elementId =
        typeof elementIdResolver === "function"
          ? elementIdResolver({
              rateIndexInFocus: context?.rateIndexInFocus,
              questionIndexInFocus: context?.questionIndexInFocus,
            })
          : elementIdResolver;

      if (!elementId) {
        setArrowTop(0);
        return;
      }

      const targetElement = iframe.contentDocument.getElementById(elementId);

      if (targetElement) {
        const elementRect = targetElement.getBoundingClientRect();
        setArrowTop(elementRect.top + elementRect.height / 2);
      } else {
        setArrowTop(0);
      }
    },
    [currentStepKey, iframeRef]
  );

  return (
    <ArrowContext.Provider
      value={{
        arrowTop,
        refreshArrowPosition,
        isArrowVisible,
        showArrow,
        hideArrow,
        transitionSpeed,
        userHasScrolled,
      }}
    >
      {children}
    </ArrowContext.Provider>
  );
};

export const useArrow = () => useContext(ArrowContext);

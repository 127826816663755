import * as React from "react";

import { FormType } from "@simplyk/common";

import { useMediaQuery } from "../../hooks/useMediaQuery";

export const getYoutubeIdentifier = (url?: string | null) => {
  if (url?.includes("youtu.be")) {
    const parsedUrlArray = url.split("/");
    return parsedUrlArray[parsedUrlArray.length - 1];
  } else if (url?.includes("https://www.youtube.com/watch?")) {
    const parsedUrlArray = url.split("=")[1].split("&");
    return parsedUrlArray[0];
  } else if (url?.includes("www.youtube.com/embed")) {
    const parsedUrlArray = url.split("?")[0].split("/");
    return parsedUrlArray[parsedUrlArray.length - 1];
  }
};

export const useBanner = (form: { bannerUrl?: string | null; bannerVideoUrl?: string | null }, context: FormType) => {
  const youtubeIdentifier = React.useMemo(() => getYoutubeIdentifier(form.bannerVideoUrl), [form]);

  // Format must be 16:9
  const dimensionsByWidth: {
    screenWidth: boolean;
    [FormType.Ticketing]: { width: number; height: number };
    [FormType.DonationForm]: { width: number; height: number };
  }[] = [
    {
      screenWidth: useMediaQuery((theme) => theme.breakpoints.only("xs")).value,
      [FormType.Ticketing]: { width: 600, height: 337.5 },
      [FormType.DonationForm]: { width: 552, height: 310.5 },
    },
    {
      screenWidth: useMediaQuery((theme) => theme.breakpoints.only("sm")).value,
      [FormType.Ticketing]: { width: 638, height: 358.87 },
      [FormType.DonationForm]: { width: 552, height: 310.5 },
    },
    {
      screenWidth: useMediaQuery((theme) => theme.breakpoints.only("md")).value,
      [FormType.Ticketing]: { width: 530.32, height: 298.84 },
      [FormType.DonationForm]: { width: 530.32, height: 298.84 },
    },
    {
      screenWidth: useMediaQuery((theme) => theme.breakpoints.only("lg")).value,
      [FormType.Ticketing]: { width: 580.17, height: 326.34 },
      [FormType.DonationForm]: { width: 608, height: 342 },
    },
    {
      screenWidth: useMediaQuery((theme) => theme.breakpoints.only("xl")).value,
      [FormType.Ticketing]: { width: 700, height: 420 },
      [FormType.DonationForm]: { width: 700, height: 420 },
    },
  ];

  const dimensionsFromCurrentWidth = dimensionsByWidth.find((dimensionByWidth) => dimensionByWidth.screenWidth);

  if (!dimensionsFromCurrentWidth) {
    return {
      youtubeIdentifier,
      width: 0,
      height: 0,
    };
  }

  const { width, height } = dimensionsFromCurrentWidth[context];

  return { youtubeIdentifier, width, height };
};

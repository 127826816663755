import { forwardRef, useCallback, useMemo, Ref, RefAttributes, useImperativeHandle, useState, type JSX } from "react";

import { convertCountryToCountryCode } from "@simplyk/common";
import { FieldValues, useController, useWatch } from "react-hook-form";

import { getLabel, isInputRequired, isPhoneNumberValid } from "../../../helpers/validators";
import { useTranslate } from "../../../hooks/useTranslate";
import { useValidators } from "../../../hooks/useValidators";

import { BasePhoneNumberInput } from "./BasePhoneNumberInput";
import { FormPhoneNumberInputProps, PhoneNumberInputHandle } from "./types";

import { useFormInputRefs } from "@/hooks/useFormInputRefs";
function InternalFormPhoneNumberInput<T extends FieldValues>(
  {
    control,
    inputRef,
    name,
    onBlur,
    onChange,
    rules,
    noAsterisk,
    organizationCountry,
    isCustomQuestion,
    ...rest
  }: FormPhoneNumberInputProps<T>,
  ref: Ref<PhoneNumberInputHandle>
) {
  const { t } = useTranslate();
  const watchedValue = useWatch({ control, name });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValueMemoized = useMemo(() => watchedValue, []);
  const [resetKey, setResetKey] = useState(0);

  const countryCode = organizationCountry ? convertCountryToCountryCode(organizationCountry) : undefined;
  const validators = useValidators(
    rules
      ? [
          ...rules,
          isPhoneNumberValid(t("common", "validation.phoneNumber"), isCustomQuestion ? undefined : countryCode),
        ]
      : [isPhoneNumberValid(t("common", "validation.phoneNumber"), isCustomQuestion ? undefined : countryCode)]
  );
  const isRequired = isInputRequired(validators);
  const { field } = useController<T>({
    name,
    control,
    defaultValue: defaultValueMemoized,
    rules: validators,
  });

  const handleBlur = useCallback(() => {
    onBlur?.();
    field.onBlur();
  }, [field, onBlur]);

  const handleChange = useCallback(
    (value: string) => {
      onChange?.(value);
      field.onChange(value);
    },
    [field, onChange]
  );

  /**
   * Cycling component key to reset the value since the
   * library did not provide a way to reset the value
   * and setting the value directly in html with js had no effect
   */
  useImperativeHandle(
    ref,
    () => ({
      resetValue: async () => {
        setResetKey((prevKey) => prevKey + 1);
      },
    }),
    []
  );

  const refCallBack = useFormInputRefs({ inputRefCallBack: field.ref, inputRef });

  return (
    <BasePhoneNumberInput
      {...rest}
      key={resetKey}
      organizationCountry={organizationCountry}
      inputRef={refCallBack}
      ref={ref as Ref<HTMLInputElement>}
      onBlur={handleBlur}
      onChange={handleChange}
      data-test={rest?.["data-test"] || "text-field"}
      label={getLabel(isRequired, rest.label, noAsterisk)}
      name={name}
      defaultValue={defaultValueMemoized}
    />
  );
}

InternalFormPhoneNumberInput.displayName = "FormPhoneNumberInput";

export const FormPhoneNumberInput = forwardRef(InternalFormPhoneNumberInput) as unknown as <T extends FieldValues>(
  props: FormPhoneNumberInputProps<T> & RefAttributes<PhoneNumberInputHandle>
) => JSX.Element;

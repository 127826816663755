import { FC } from "react";

import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";

import { getChoiceAvailableCount } from "../../../../components/CustomAnswer/choices.helper";
import { Typography } from "../../../../components/design-system/Typography";
import { useLocaleContext } from "../../../../contexts/LocaleContext";
import * as useTranslate from "../../../../hooks/useTranslate";
import { getChoiceLabel } from "../../helpers/getLabel";

import { ChoiceOutput } from "@/types/trpc";

export interface QuestionFieldChoiceProps {
  choice: ChoiceOutput;
  answersOfParticipantsSelectedChoicesCount: Record<string, number> | undefined;
  hideAvailability?: boolean;
}

export const QuestionFieldChoice: FC<QuestionFieldChoiceProps> = ({
  choice,
  answersOfParticipantsSelectedChoicesCount,
  hideAvailability,
}) => {
  const theme = useTheme();
  const { t } = useTranslate.useTranslate();
  const { locale } = useLocaleContext();
  const label = getChoiceLabel(choice.choiceFields || [], locale);
  const maximum = choice.maximum;
  const availableCount = getChoiceAvailableCount(choice, answersOfParticipantsSelectedChoicesCount);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        columnGap: 2,
      }}
    >
      {/* Label */}
      <Typography
        variant="body2"
        sx={{
          flexGrow: 1,
          whiteSpace: "normal",
          color: `${theme.palette.text.form.moderate} !important`,
        }}
        vibe="text-form-moderate"
        data-test="choice-label-typography"
      >
        {label}
      </Typography>

      {/* Availability */}
      {maximum !== null && maximum !== undefined && maximum >= 0 && !hideAvailability && (
        <Typography variant="caption" data-test="choice-label-available-typography" vibe="text-form-quiet">
          {availableCount > 0
            ? t("ticketing", "choice.availableCount", {
                count: availableCount,
              })
            : t("ticketing", "choice.notAvailable")}
        </Typography>
      )}
    </Box>
  );
};

import { useCallback, useEffect, useRef, useState } from "react";

import { Box, useTheme } from "@mui/material";

import { sanitizeHtmlString } from "../../helpers/html";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useTranslate } from "../../hooks/useTranslate";
import { Typography } from "../design-system/Typography";

interface ClampedTextProps {
  text?: string | null;
  fallbackMaxHeight?: string;
  maxLines: number;
  isInitiallyExpanded?: boolean;
  dataTest?: string;
  getIsExpanded?: (isExpanded: boolean) => void;
}

export const ClampedText = ({
  text,
  fallbackMaxHeight = "7.5em",
  maxLines = 5,
  isInitiallyExpanded = false,
  dataTest,
  getIsExpanded,
}: ClampedTextProps) => {
  const { t } = useTranslate();
  const trimmedText = sanitizeHtmlString(text);
  const { isSmallScreen } = useMediaQuery();
  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);
  const [isClampNeeded, setIsClampNeeded] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const [maxHeightByLines, setMaxHeightByLines] = useState<number | null>(null);
  const [contentHeight, setContentHeight] = useState(0); // Enable transition on height change

  useEffect(() => {
    if (contentRef.current) {
      const element = contentRef.current;
      setContentHeight(element.scrollHeight);

      const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
      const maxHeight = lineHeight * maxLines;
      setMaxHeightByLines(lineHeight * maxLines);
      setIsClampNeeded(element.scrollHeight > maxHeight);
    }
  }, [maxLines, trimmedText, isSmallScreen]);

  const toggleExpand = useCallback(() => {
    getIsExpanded?.(!isExpanded);
    setIsExpanded((prev) => !prev);
  }, [getIsExpanded, isExpanded]);

  if (!trimmedText) {
    return null;
  }

  return (
    <Box sx={{ position: "relative" }} data-test={dataTest}>
      <Box
        sx={{
          maxHeight: isExpanded ? `${contentHeight}px` : maxHeightByLines ? `${maxHeightByLines}px` : fallbackMaxHeight,
          transition: "max-height 0.2s ease-in",
          overflow: "hidden",
          ...(isClampNeeded &&
            !isExpanded && {
              maskImage: "linear-gradient(to bottom, black 90%, transparent 100%)",
              WebkitMaskImage: "linear-gradient(to bottom, black 90%, transparent 100%)",
            }),
        }}
      >
        <Typography
          ref={contentRef}
          variant="body2"
          vibe="text-form-moderate"
          component="div"
          textOverflow="clip"
          data-test="expandable-text-description-clamped"
          sx={{
            "& p": { color: "inherit !important" }, // Override the color that came from DB for live editor preview
          }}
          dangerouslySetInnerHTML={{ __html: trimmedText }}
        />
      </Box>

      {isClampNeeded && (
        <Typography
          variant="body2"
          vibe="text-form-supershy"
          onClick={toggleExpand}
          data-test="expandable-text-expand-toggle"
          sx={{
            color: theme.palette.text.neutral.quiet,
            cursor: "pointer",
            display: "inline-block",
            mt: 1,
            transition: "all 0.2s ease-in",
            "&:hover": {
              textDecoration: "underline",
            },
            "&::after": {
              content: '"..."',
            },
          }}
        >
          {isExpanded ? t("common", "seeLess") : t("common", "more")}
        </Typography>
      )}
    </Box>
  );
};

import { useContext, useMemo } from "react";

import { CommandStatus, FormSubmissionStatus } from "@simplyk/common";
import { DateTime } from "luxon";
import { Control, useFormState, useWatch } from "react-hook-form";

import { TicketingFormData } from "../components/TicketingDrawers/type";
import { FrontendFormContext } from "../contexts/FrontendFormContext";
import { FrontendTicketingContext } from "../contexts/FrontendTicketingContext";
import { useLocaleContext } from "../contexts/LocaleContext";
import { CommandLang, CommandSource } from "../gql/gql-types";

import { useUpsertCommand } from "./useUpsertCommand";
import { useUpsertVisitorTracking } from "./useUpsertVisitorTracking";

import { useIsFormV2 } from "@/features/FormV2/hooks/useIsFormV2";
import { convertCountryToCountryCode, serializeProductTickets } from "@/helpers/command";
import { getDiscountAmount } from "@/helpers/order";
import { getEligibleAmountTotal } from "@/helpers/rates";
import { computeTicketsPrice } from "@/helpers/ticket";

interface TicketingFormUpdatedProps {
  control: Control<TicketingFormData>;
}

export const useTicketingFormUpdated = ({ control }: TicketingFormUpdatedProps) => {
  const { isDirty, isSubmitting, isSubmitSuccessful } = useFormState({ control });
  const isFormV2 = useIsFormV2();
  const { locale } = useLocaleContext();
  const {
    tip,
    displayedFormAmount,
    selectedPaymentMethod,
    formData,
    formType,
    organization,
    shouldSendTipPercentage,
    selectedTip,
    category,
  } = useContext(FrontendFormContext);

  const { ticketing, validDiscount } = useContext(FrontendTicketingContext);
  const email = useWatch({ control, name: "email" });
  const firstName = useWatch({ control, name: "firstName" });
  const lastName = useWatch({ control, name: "lastName" });
  const address = useWatch({ control, name: "address" });
  const city = useWatch({ control, name: "city" });
  const postalCode = useWatch({ control, name: "postalCode" });
  const region = useWatch({ control, name: "region" });
  const formCountry = useWatch({ control, name: "country" });
  const discountId = useWatch({ control, name: "discountId" });
  const extraDonation = useWatch({ control, name: "extraDonation" });
  const isCorporate = useWatch({ control, name: "giveAsOrganism" });
  const corporationName = useWatch({ control, name: "companyName" });
  const ticketsPurchased = useWatch({ control, name: "ticketsPurchased" });
  const occurrenceId = useWatch({ control, name: "occurrenceId" });

  const country = useMemo(() => convertCountryToCountryCode(formCountry), [formCountry]);
  const eligibleAmount = useMemo(
    () => getEligibleAmountTotal(ticketsPurchased, ticketing, extraDonation || 0, validDiscount),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [extraDonation, validDiscount, JSON.stringify(ticketsPurchased)]
  );

  const discountAmount = useMemo(() => {
    if (!ticketing.rates || !ticketsPurchased || !validDiscount) {
      return 0;
    }

    const ticketsPrice = computeTicketsPrice(ticketing.rates, ticketsPurchased);
    return getDiscountAmount(validDiscount, ticketsPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validDiscount, JSON.stringify(ticketsPurchased)]);

  const productTickets = serializeProductTickets(ticketsPurchased);

  useUpsertVisitorTracking({
    props: {
      formId: formData.id,
      organizationId: organization.id,
      formSubmissionStatus: FormSubmissionStatus.Incomplete,
      firstName,
      lastName,
      email,
      formCategory: category,
    },
    isDirty,
    shouldCancel: isSubmitting || isSubmitSuccessful,
  });

  useUpsertCommand(
    {
      email,
      firstName,
      lastName,
      address,
      city,
      postalCode,
      region,
      country,
      discountAmount,
      eligibleAmount,
      extraDonation,
      discountId,
      isCorporate,
      corporationName,
      ticketingOccurrenceId: occurrenceId,
      tipAmount: (!shouldSendTipPercentage ? tip : 0) || 0,
      tipPercentage: (shouldSendTipPercentage ? selectedTip.percentage : 0) || 0,
      productsAmount: displayedFormAmount,
      totalAmount: displayedFormAmount + tip,
      paymentMethod: selectedPaymentMethod,
      productTickets,
      productDonation: null,
      productBids: null,
      formId: formData.id,
      formLang: CommandLang[locale],
      formType: formType || null,
      locale,
      organizationId: organization.id,
      source: CommandSource.FormSubmission,
      status: CommandStatus.Open,
      userTimezone: DateTime.local().zoneName || "UTC",
      isFormV2,
    },
    isDirty,
    isSubmitting || isSubmitSuccessful
  );
};

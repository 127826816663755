import { createContext, useContext } from "react";

import {
  AvailablePaymentCurrency,
  DonationFormCategory,
  FormType,
  OrganizationCountry,
  StripeRecurringInterval,
  TicketingFormCategory,
} from "@simplyk/common";
import { UseFieldArrayReturn, UseFormReturn } from "react-hook-form";

import { ManualCommandGeneralInfoForm } from "../../../components/ManualCommandDrawer/ManualCommandGeneralInfo/ManualCommandGeneralInfo";
import { OrganizationObject } from "../../../gql/gql-types";
import { CustomAnswer } from "../../../types/customQuestion";
import { FormV2StepKey } from "../types/FormV2StepKey";

import { TicketingPaymentInput, TicketPurchase } from "@/types/ticketing";
import {
  DonationFormOutput,
  DonationFormAmountOutput,
  DonationFormFieldOutput,
  QuestionOutput,
  RateOutput,
  TicketingOutput,
  TicketingFieldOutput,
} from "@/types/trpc";

export type DonationPaymentInput = ManualCommandGeneralInfoForm & {
  answers: CustomAnswer[];
  amount: number;
  selectedAmountId?: string;
  recurrenceInterval: StripeRecurringInterval | null;
  tip: number;
  inHonourOf: boolean;
  inHonourName: string;
  inHonourNotifyEmail: string;
  inHonourMessage: string;
  notifySomeone: boolean;
  isPublicDonation: boolean;
};
export type TicketingFormManager = UseFormReturn<TicketingPaymentInput>;

interface BaseFormV2Context {
  type: FormType.DonationForm | FormType.Ticketing;
  currentStep: FormV2StepKey;
  setCurrentStep: (step: FormV2StepKey) => void;
  setValidationMode: (mode: "onBlur" | "onChange") => void;
  resetForm: () => void;
  hasBanner: boolean;
  formFields: TicketingFieldOutput[] | DonationFormFieldOutput[];
  localeField: TicketingFieldOutput | DonationFormFieldOutput;
  description: string | undefined;
  isMembership: boolean;
  isAuction: boolean;
  formCategory: TicketingFormCategory | DonationFormCategory;
  formCountry: OrganizationCountry;
  formCurrency: AvailablePaymentCurrency;
  formOrganization: OrganizationObject;
  questions: QuestionOutput[];
  ticketsPurchased: TicketPurchase[] | undefined;
  displayGenerateReceipt: boolean;
  eligibleAmountTotal: number;
}

export interface TicketingFormV2Context extends BaseFormV2Context {
  type: FormType.Ticketing;
  formObject: TicketingOutput;
  form: UseFormReturn<TicketingPaymentInput>;
  formCategory: TicketingFormCategory;
  formFields: TicketingFieldOutput[];
  localeField: TicketingFieldOutput;

  // Ticketing-specific data
  customAnswersOfParticipantsFieldArray: UseFieldArrayReturn<TicketingPaymentInput, "customAnswersOfParticipants">;
  validRatesSorted: RateOutput[];
  hasDate: boolean;
  hasLocation: boolean;
}

export interface DonationFormV2Context extends BaseFormV2Context {
  type: FormType.DonationForm;
  formObject: DonationFormOutput;
  form: UseFormReturn<DonationPaymentInput>;
  formFields: DonationFormFieldOutput[];
  localeField: DonationFormFieldOutput;
  formCategory: DonationFormCategory;
  suggestedAmounts: DonationFormAmountOutput[];
  hasSuggestedAmountsPerType: {
    oneTime: boolean;
    monthly: boolean;
    yearly: boolean;
  };
  amountRecurrenceInterval: {
    onlyOneType: boolean;
    onlyMonthly: boolean;
    onlyYearly: boolean;
  };
}

export type FormV2ContextType = TicketingFormV2Context | DonationFormV2Context;

export const FormV2Context = createContext<FormV2ContextType>({
  type: FormType.Ticketing,
  formObject: {} as TicketingOutput,
  form: {} as TicketingFormManager,
  customAnswersOfParticipantsFieldArray: {} as UseFieldArrayReturn<
    TicketingPaymentInput,
    "customAnswersOfParticipants"
  >,
  currentStep: "selection",
  setCurrentStep: () => {},
  validRatesSorted: [],
  resetForm: () => {},
  hasDate: false,
  hasLocation: false,
  setValidationMode: () => {},
  hasBanner: false,
  isMembership: false,
  isAuction: false,
  formFields: [],
  localeField: {} as TicketingFieldOutput,
  description: undefined,
  formCategory: TicketingFormCategory.Event,
  questions: [],
  ticketsPurchased: undefined,
  formOrganization: {} as OrganizationObject,
  formCountry: OrganizationCountry.UnitedStates,
  formCurrency: AvailablePaymentCurrency.Usd,
  displayGenerateReceipt: false,
  eligibleAmountTotal: 0,
});

export interface CommonFormContext extends BaseFormV2Context {
  form: UseFormReturn<ManualCommandGeneralInfoForm>;
}

/**
 * A typed hook to consume the FormV2Context.
 *
 * @param expectedType optionally pass "ticketing" or "donation".
 * If passed, TypeScript automatically narrows the return type so you can directly use e.g. "validRatesSorted" in ticketing or "recurrenceInterval" fields if "donation".
 */

// Overload #1: user calls useFormV2Context("common") -> returns the CommonFormContext
export function useFormV2Context(expectedType: "common"): CommonFormContext;

// Overload #2: user calls useFormV2Context(FormType.Ticketing) -> returns TicketingFormV2Context
export function useFormV2Context(expectedType: FormType.Ticketing): TicketingFormV2Context;

// Overload #3: user calls useFormV2Context(FormType.DonationForm) -> returns DonationFormV2Context
export function useFormV2Context(expectedType: FormType.DonationForm): DonationFormV2Context;

// Overload #4: user calls useFormV2Context() with no args -> returns the union of everything
export function useFormV2Context(): FormV2ContextType;

// Implementation
export function useFormV2Context(
  expectedType?: "common" | FormType.Ticketing | FormType.DonationForm
): FormV2ContextType | CommonFormContext {
  const context = useContext(FormV2Context);

  if (expectedType && expectedType !== "common" && context.type !== expectedType) {
    // eslint-disable-next-line no-console
    console.error(`FormV2Context is type="${context.type}", but you requested "${expectedType}".`);

    if (process.env.NODE_ENV === "development") {
      throw new Error(`FormV2Context is type="${context.type}", but you requested "${expectedType}".`);
    }
  }

  // If the user specifically asked for "common", then we cast the context to your new shape
  // that has all the "base" fields but form typed as "UseFormReturn<CommonFields>".
  if (expectedType === "common") {
    return {
      ...context,
      form: context.form as unknown as UseFormReturn<ManualCommandGeneralInfoForm>,
    };
  }

  // Otherwise, return the real context as-is
  return context;
}

import { useRef, useEffect, useCallback } from "react";

/**
 * Custom hook to manage an animation queue.
 *
 * @param maxQueueLength - The maximum number of animations that can be queued.
 * @returns A function to enqueue new animations.
 */
export const useAnimationQueue = ({ maxQueueLength }: { maxQueueLength: number } = { maxQueueLength: 1 }) => {
  // Reference to store the queue of animation functions
  const animationQueueRef = useRef<(() => void)[]>([]);

  // Flag to indicate if an animation is currently running
  const isAnimatingRef = useRef<boolean>(false);

  // Reference to store active timeouts for cleanup
  const timeoutsRef = useRef<NodeJS.Timeout[]>([]);

  /**
   * Executes the next animation in the queue.
   */
  const executeNext = useCallback(() => {
    if (animationQueueRef.current.length === 0) {
      isAnimatingRef.current = false;
      return;
    }

    isAnimatingRef.current = true;
    const nextAnimation = animationQueueRef.current.shift();
    if (nextAnimation) {
      nextAnimation();
    }
  }, []);

  /**
   * Enqueues a new animation.
   *
   * @param animation - The animation function to enqueue.
   */
  const enqueueAnimation = useCallback(
    (animation: () => void) => {
      if (isAnimatingRef.current) {
        if (animationQueueRef.current.length < maxQueueLength) {
          animationQueueRef.current.push(animation);
        } else {
          // Replace the last animation in the queue if max length is reached
          animationQueueRef.current[animationQueueRef.current.length - 1] = animation;
        }
      } else {
        animationQueueRef.current.push(animation);
        executeNext();
      }
    },
    [executeNext, maxQueueLength]
  );

  /**
   * Callback to be invoked when an animation completes.
   */
  const onAnimationComplete = useCallback(() => {
    executeNext();
  }, [executeNext]);

  /**
   * Resets the queue when the component unmounts.
   */
  useEffect(() => {
    return () => {
      animationQueueRef.current = [];
      isAnimatingRef.current = false;
    };
  }, []);

  return { enqueueAnimation, onAnimationComplete, timeoutsRef, isAnimatingRef };
};

import { FunctionComponent, useMemo } from "react";

import classnames from "classnames";

import { useStyles } from "./useStyles";

import { Icon, IconProps } from "@/design-system/Icon";
import { Tooltip, TooltipProps } from "@/design-system/Tooltip";
import { Info as InfoIcon } from "@/icons/outlined";

interface InformationTooltipProps extends Omit<TooltipProps, "children" | "classes"> {
  alignOnText?: boolean;
  alignOffset?: string;
  iconProps?: IconProps;
  classes?: {
    root?: string;
  };
}

export const InformationTooltip: FunctionComponent<React.PropsWithChildren<InformationTooltipProps>> = ({
  alignOnText,
  alignOffset,
  iconProps,
  arrow = true,
  classes,
  ...tooltipProps
}) => {
  const internalClasses = useStyles();

  const spanStyle = useMemo(
    () => ({
      top: alignOffset || "0.25rem",
    }),
    [alignOffset]
  );

  return (
    <span style={spanStyle} className={classnames(classes?.root, { [internalClasses.tooltipContainer]: alignOnText })}>
      <Tooltip {...tooltipProps} arrow={arrow}>
        <Icon size="small" {...iconProps}>
          <InfoIcon />
        </Icon>
      </Tooltip>
    </span>
  );
};

const isBr = (node: Node) => {
  return node.nodeType === Node.ELEMENT_NODE && (node as Element).tagName.toLowerCase() === "br";
};

const isImage = (node: Node) => {
  return node.nodeType === Node.ELEMENT_NODE && (node as Element).tagName.toLowerCase() === "img";
};

const isSvg = (node: Node) => {
  return node.nodeType === Node.ELEMENT_NODE && (node as Element).tagName.toLowerCase() === "svg";
};

const isPBr = (node: Node) => {
  return (
    node.nodeType === Node.ELEMENT_NODE &&
    (node as Element).tagName.toLowerCase() === "p" &&
    (node as Element).innerHTML.includes("<br")
  );
};

const isEmptyElement = (node: Node) => {
  const _ = (node as Element).innerHTML;

  return (
    node.nodeType === Node.ELEMENT_NODE &&
    !isImage(node) &&
    !isSvg(node) &&
    !(node as Element).textContent?.trim() &&
    !(node as Element).innerHTML.includes("<img") &&
    !(node as Element).innerHTML.includes("<svg")
  );
};

// Remove empty elements (elements with no text content)
// Keep one <br> tag when it separates two non-empty paragraphs
const removeEmptyElements = (node: Node, trim: boolean) => {
  const childNodes = Array.from(node.childNodes);

  let minNode = 0;
  let maxNode = childNodes.length - 1;

  if (trim) {
    // Remove all leading <br> tags
    for (; minNode <= maxNode; minNode++) {
      const child = childNodes[minNode];

      if (isBr(child) || isEmptyElement(child)) {
        child.remove();
      } else {
        break;
      }
    }

    // Remove all trailing <br> tags
    for (; maxNode > minNode; maxNode--) {
      const child = childNodes[maxNode];

      if (isBr(child) || isEmptyElement(child)) {
        child.remove();
      } else {
        break;
      }
    }
  }

  let isFirstEmptyParagraph = true;

  for (let i = minNode; i <= maxNode; i++) {
    const child = childNodes[i];

    if (child.nodeType === Node.ELEMENT_NODE) {
      if (isBr(child) || isImage(child) || isSvg(child)) {
        continue;
      }

      removeEmptyElements(child, false);

      if (isEmptyElement(child)) {
        if (isPBr(child)) {
          if (!isFirstEmptyParagraph) {
            child.remove();
          } else {
            isFirstEmptyParagraph = false;
          }
        } else {
          child.remove();
        }
      } else {
        isFirstEmptyParagraph = true;
      }
    }
  }
};

/**
 * Sanitize HTML string by removing:
 * 1. Leading and trailing whitespace,
 * 2. Script tags
 * 3. Empty HTML elements
 *
 * @param htmlString to be sanitized
 * @returns Sanitized HTML
 */
export const sanitizeHtmlString = (htmlString: string | null | undefined): string | null | undefined => {
  if (!htmlString) {
    return htmlString;
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const scripts = doc.getElementsByTagName("script");

  Array.from(scripts).forEach((script) => {
    script.remove();
  });

  removeEmptyElements(doc.body, true);

  return doc.body.innerHTML.trim();
};

import { FC } from "react";

import { Box } from "@mui/material";
import { FormType } from "@simplyk/common";

import { ClampedText } from "../../../components/ClampedText/ClampedText";
import { Typography } from "../../../components/design-system/Typography";
import { ExpandableText } from "../../../components/ExpandableText/ExpandableText";
import { sanitizeHtmlString } from "../../../helpers/html";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { STEP_TO_ELEMENT_ID } from "../../LiveFormEditor/AnimatePreview/Arrow/ArrowContext";
import { usePreviewContext } from "../../LiveFormEditor/LivePreview/context/PreviewContext";
import { GHOST_PREVIEW_OPACITY } from "../../LiveFormEditor/LivePreview/ghostPreview.constant";
import { useIsGhostPreview } from "../../LiveFormEditor/LivePreview/useIsGhostPreview";
import { useFormV2Context } from "../context/FormV2Context";

export const FormV2Description: FC & { ID: string } = () => {
  const { isSmallScreen } = useMediaQuery();
  const { hasBanner, description, type } = useFormV2Context();

  const { isPreview, placeholders, currentStepKey } = usePreviewContext();
  const isGhostPreview = useIsGhostPreview("FormV2Description");

  if (!description) {
    return null;
  }

  const descriptionContainsImage = /<img\b[^>]*>/i.test(description || "");
  const shouldUseExpandableText = isSmallScreen || descriptionContainsImage;

  const isDonationForm = type === FormType.DonationForm;
  const isDescriptionStep = currentStepKey === "description";
  const hasDescriptionPlaceholder = placeholders.isDescription;

  const initiallyExpanded =
    (isDonationForm && isSmallScreen && !isPreview) || // For donation form, we always expand the description on small screens
    (isPreview && (!isSmallScreen || (isDescriptionStep && !hasDescriptionPlaceholder))); // Live Form preview Logic

  return (
    <Box
      data-test="form-v2-description"
      sx={{
        maxWidth: "100%", // Must keep this to prevent overflow from container
        marginBottom: { xs: 0, md: !hasBanner ? 0 : 5 },
        opacity: isGhostPreview ? GHOST_PREVIEW_OPACITY : 1,
      }}
    >
      {!isSmallScreen ? (
        <Typography
          variant="body2"
          vibe="text-form-moderate"
          component="div"
          sx={{
            "& p": {
              color: "inherit !important", // Override the color that came from DB for live editor preview
              width: "auto !important",
            },
          }}
          dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(description) || "" }}
        />
      ) : shouldUseExpandableText ? (
        <ExpandableText
          text={description}
          isInitiallyExpanded={initiallyExpanded}
          textProps={(isExpanded: boolean) => (isExpanded ? { id: STEP_TO_ELEMENT_ID["description"] } : {})}
          buttonProps={(isExpanded: boolean) => (isExpanded ? {} : { id: STEP_TO_ELEMENT_ID["description"] })}
        />
      ) : (
        <ClampedText text={description} isInitiallyExpanded={initiallyExpanded} maxLines={5} />
      )}
    </Box>
  );
};

FormV2Description.ID = "form-v2-description";

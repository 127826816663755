import { Box, SxProps, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { OrganizationCountry } from "@simplyk/common";
import { FieldValues, Path, useFormContext } from "react-hook-form";

import { Checkbox } from "../../../../components/design-system/Checkbox";
import { DatePicker } from "../../../../components/design-system/DatePicker";
import { EmailTextField } from "../../../../components/design-system/EmailTextField";
import { PhoneNumberInput } from "../../../../components/design-system/PhoneNumberInput";
import { TextField } from "../../../../components/design-system/TextField";
import { useLocaleContext } from "../../../../contexts/LocaleContext";
import { getNestedErrorMessage } from "../../../../helpers/reactHookForm";
import { isRequired } from "../../../../helpers/validators";
import { useTranslate } from "../../../../hooks/useTranslate";
import { getQuestionLabel } from "../../helpers/getLabel";

import { QuestionFieldMultipleCheckboxes } from "./QuestionFieldMultipleCheckboxes";
import { QuestionFieldName } from "./QuestionFieldName";
import { QuestionFieldSelect } from "./QuestionFieldSelect";

import { usePreviewContext } from "@/features/LiveFormEditor/LivePreview/context/PreviewContext";
import { QuestionOutput } from "@/types/trpc";

export interface Props<T extends FieldValues> {
  name: Path<T>;
  question: QuestionOutput;
  id?: string;
  organizationCountry?: OrganizationCountry;
  sx?: SxProps<Theme>;
}

const useStyles = makeStyles((theme) => ({
  colored: {
    color: `${theme.palette.text.form.intense} !important`,
  },
}));

export function QuestionField<T extends FieldValues>({
  question,
  name,
  id,
  organizationCountry = OrganizationCountry.Canada,
  sx,
}: Props<T>) {
  const classes = useStyles();
  const { locale } = useLocaleContext();
  const { isPreview } = usePreviewContext();
  const { t } = useTranslate();
  const form = useFormContext<T>();
  const { control, formState, register } = form;
  const label = getQuestionLabel(question, locale);
  const required = Boolean(question?.required);

  const choiceName = `${name}.choiceIds.0` as Path<T>;
  const textName = `${name}.answers.0` as Path<T>;
  const questionIdField = `${name}.questionId` as Path<T>;

  let choiceErrorMessage = getNestedErrorMessage(choiceName, formState);
  let textErrorMessage = getNestedErrorMessage(textName, formState);

  // We prevent errors from being shown in the live preview
  if (isPreview) {
    choiceErrorMessage = undefined;
    textErrorMessage = undefined;
  }

  const renderInputField = () => {
    switch (question.type) {
      case "Email":
        return (
          <EmailTextField
            name={textName}
            label={label}
            control={control}
            required={required}
            rules={[isRequired(required, t("common", "answerRequired"))]}
            errorMessage={textErrorMessage}
            type="email"
            checkMisspelledEmail
            data-test="question-field-email"
            disableInjectingGlobalStyles={isPreview} // We need this to make the LivePreview smooth
          />
        );

      case "PhoneNumber":
        return (
          <PhoneNumberInput
            name={textName}
            label={label}
            control={control}
            rules={[isRequired(required, t("common", "answerRequired"))]}
            errorMessage={textErrorMessage}
            data-test="question-field-phone-number"
            isCustomQuestion={true}
            organizationCountry={organizationCountry}
          />
        );

      case "Date":
        return (
          <DatePicker
            name={textName}
            label={label}
            control={control}
            rules={[isRequired(required, t("common", "answerRequired"))]}
            errorMessage={textErrorMessage}
            data-test="question-field-date"
          />
        );

      case "MultipleCheckboxes":
        return (
          <QuestionFieldMultipleCheckboxes
            name={name}
            question={question}
            form={form}
            label={label}
            data-test="question-field-multiple-checkboxes"
          />
        );

      case "Checkbox":
        return (
          <Checkbox
            name={textName}
            label={label}
            control={control}
            rules={[isRequired(required, t("common", "answerRequired"))]}
            size="small"
            classes={{ root: classes.colored }}
            errorMessage={textErrorMessage}
            data-test="question-field-checkbox"
          />
        );

      case "Multiple":
        return (
          <QuestionFieldSelect
            name={choiceName}
            question={question}
            form={form}
            label={label}
            errorMessage={choiceErrorMessage}
            isPreview={isPreview}
          />
        );

      case "Name":
        return (
          <QuestionFieldName
            name={textName}
            form={form}
            question={question}
            errorMessage={textErrorMessage}
            isPreview={isPreview}
          />
        );

      default:
        return (
          <TextField
            name={textName}
            label={label}
            control={control}
            required={required}
            rules={[isRequired(required, t("common", "answerRequired"))]}
            errorMessage={textErrorMessage}
            vibe="form"
            data-test="question-field-text"
            disableInjectingGlobalStyles={isPreview} // We need this to make the LivePreview smooth
          />
        );
    }
  };

  return (
    <Box data-test="question-field" sx={sx} id={id}>
      {/* Hidden input to hold question id */}
      <input type="hidden" {...register(questionIdField)} value={question.id} />

      {renderInputField()}
    </Box>
  );
}

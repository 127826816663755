import { RateOutput } from "@/types/trpc";

const AUCTION_SORT_ORDER: Record<"isOutbid" | "isWinning" | "YouDidNotBidYet", number> = {
  isOutbid: 0,
  isWinning: 1,
  YouDidNotBidYet: 2,
};

type GetValidRatesSortedParams = {
  rates: RateOutput[];
  isAuction: boolean;
  commandBidsRateIdsMap?: Record<string, { amount: number }> | null;
  highestBidsMapByRateId?: Record<string, { amount: number }>;
};

export function getValidRatesSorted({
  rates,
  isAuction,
  commandBidsRateIdsMap,
  highestBidsMapByRateId,
}: GetValidRatesSortedParams): RateOutput[] {
  // Filter out expired early bird rates
  const filtered = rates.filter((rate) => {
    if (rate.earlyBirdCloseDate) {
      return new Date(rate.earlyBirdCloseDate) > new Date();
    }
    return true;
  });

  // Apply Auction sort if it's an auction form and we have the bid maps
  if (isAuction && commandBidsRateIdsMap && highestBidsMapByRateId) {
    return filtered.sort((a, b) => {
      const aStatus = getAuctionBidStatus(a.id, commandBidsRateIdsMap, highestBidsMapByRateId);
      const bStatus = getAuctionBidStatus(b.id, commandBidsRateIdsMap, highestBidsMapByRateId);

      // First, sort by auction status (isOutbid < isWinning < YouDidNotBidYet)
      const statusComparison = AUCTION_SORT_ORDER[aStatus] - AUCTION_SORT_ORDER[bStatus];
      if (statusComparison !== 0) {
        return statusComparison;
      }

      // If auction status is the same, sort by sortIndex
      return (a.sortIndex ?? 0) - (b.sortIndex ?? 0);
    });
  }

  // Non-auction → fallback to default sortIndex
  return filtered.sort((a, b) => (a.sortIndex ?? 0) - (b.sortIndex ?? 0));
}

export function getAuctionBidStatus(
  rateId: string,
  commandBidsRateIdsMap: Record<string, { amount: number }>,
  highestBidsMapByRateId: Record<string, { amount: number }>
): "isOutbid" | "isWinning" | "YouDidNotBidYet" {
  const currentBidAmount = commandBidsRateIdsMap?.[rateId]?.amount;
  const highestBidAmount = highestBidsMapByRateId?.[rateId]?.amount;
  const hasABid = currentBidAmount !== undefined && currentBidAmount !== null;
  const isWinning = hasABid && currentBidAmount === highestBidAmount;

  if (!hasABid) {
    return "YouDidNotBidYet";
  }
  return isWinning ? "isWinning" : "isOutbid";
}

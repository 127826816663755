import { forwardRef, Ref, RefAttributes, type JSX } from "react";

import { FieldValues } from "react-hook-form";

import { BasePhoneNumberInput } from "./BasePhoneNumberInput";
import { FormPhoneNumberInput } from "./FormPhoneNumberInput";
import { PhoneNumberInputHandle, PhoneNumberInputProps } from "./types";

function PhoneNumberInputInner<T extends FieldValues>(
  { control, name, rules, ...rest }: PhoneNumberInputProps<T>,
  ref: Ref<PhoneNumberInputHandle>
) {
  return control && name ? (
    <FormPhoneNumberInput {...rest} ref={ref} control={control} name={name} rules={rules} />
  ) : (
    <BasePhoneNumberInput {...rest} name={name} ref={ref as Ref<HTMLInputElement>} />
  );
}

PhoneNumberInputInner.displayName = "InternalPhoneNumberInput";

export const PhoneNumberInput = forwardRef(PhoneNumberInputInner) as unknown as <T extends FieldValues>(
  props: PhoneNumberInputProps<T> & RefAttributes<PhoneNumberInputHandle>
) => JSX.Element;
